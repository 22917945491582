import getAxiosInstance from "./httpCommon";
import IRideProperties from "../models/rideProperties/IRideProperties";

const baseURL = "/api/drives"
const axios = getAxiosInstance(); // Get the Axios instance
const getAll = async () => {
  return await axios.get<Array<IRideProperties>>(`${baseURL}`);
};

const get = async (ride: IRideProperties) => {
    return await axios.get<Array<IRideProperties>>(`${baseURL}`,
    { params: {
        origin: ride.origin,
        destination: ride.destination,
        date: ride.date,
        }
     })
  };

  const getUserRides = async (email: string) => {
    return await axios.get<Array<IRideProperties>>(`${baseURL}/userRides`,
    { params: {
        email: email,
        }
     })
  };

  const getUserJoiningRides = async (email: string) => {
    return await axios.get<Array<IRideProperties>>(`${baseURL}/userJoiningRides`,
    { params: {
        email: email,
        }
     })
  };

const create = async (ride: IRideProperties) => {
  return await axios.post<IRideProperties>(`${baseURL}`, ride);
};

const remove = async (id: number) => {
    return await axios.delete(`${baseURL}/${id}`);
};

const TutorialService = {
  getAll,
  get,
  create,
  remove,
  getUserRides,
  getUserJoiningRides
};

export default TutorialService;
