import { IOptions } from "./IOptions";

export const PriceOptions: IOptions[] = [
    { value: "TelAviv", text: "16" },
    { value: "Heyfa", text: "30" },
    { value: "Jerusalem", text: "15" },
    { value: "RamatGan", text: "16" },
    { value: "BetEl", text: "בת אל" },
    { value: "Nahria", text: "40" },
    { value: "Carmiel", text: "40" },
    { value: "Tveria", text: "35" },
    { value: "Galil", text: "35" },
    { value: "Zfat", text: "40" },
    { value: "Kiryat Shmuna", text: "40" },
    { value: "Ramat Hagolan Kazarin", text: "40" },
    { value: "Ramat Hagolan Korzi", text: "35" },
    { value: "Ramat Hagolan Zemah", text: "30" },
    { value: "Emek Izrael", text: "40" },
    { value: "Ykneam", text: "30" },
    { value: "Beit She'an", text: "25" },
    { value: "Zikhron Ya'akov / Carmel Coast", text: "30" },
    { value: "Hadera", text: "20" },
    { value: "Harish", text: "20" },
    { value: "Gedera", text: "20" },
    { value: "Ashkelon", text: "25" },
    { value: "Ashdod", text: "20" },
    { value: "Sderot", text: "30" },
    { value: "Netivot", text: "30" },
    { value: "Kiryat Gat", text: "25" },
    { value: "Beer Sheva (and Surroundings)", text: "30" },
    { value: "Giv'atayim", text: "16" },
    { value: "Holon", text: "16" },
    { value: "Bat Yam", text: "16" },
    { value: "Rishon LeZion", text: "16" },
    { value: "Petah Tikva", text: "12" },
    { value: "Giv'at Shmuel", text: "12" },
    { value: "Kiriat Aono", text: "12" },
    { value: "Bikat Auno", text: "12" },
    { value: "Hashmonaim", text: "16" },
    { value: "Modi'in", text: "16" },
    { value: "Rehovot", text: "20" },
    { value: "Beit Shemesh", text: "20" },
    { value: "Nes Ziona", text: "20" },
    { value: "Ramlah", text: "16" },
    { value: "Lod", text: "16" },
    { value: "Emek Hefer", text: "16" },
    { value: "Netanya", text: "16" },
    { value: "Kfar Yona", text: "16" },
    { value: "Kadima-Zoran", text: "16" },
    { value: "Herzliya", text: "12" },
    { value: "Ramat Hasharon", text: "12" },
    { value: "Hod Hasharon", text: "12" },
    { value: "Kfar Saba", text: "12" },
    { value: "Raanana", text: "12" },
    { value: "Gush Etzion", text: "20" },
    { value: "Kiryat Arba", text: "20" },
    { value: "Barkan", text: "6.5" },
    { value: "Oranit", text: "6.5" },
    { value: "Shaarey Tikva", text: "6.5" },
    { value: "Elkana", text: "6.5" },
    { value: "Har Bracha", text: "6.5" },
    { value: "Kdumim", text: "6.5" },
    { value: "Avnie Hefetz", text: "6.5" },
    { value: "Tzofim", text: "6.5" },
    { value: "Ali", text: "6.5"},
    { value: "Shiloh", text: "6.5" },
    { value: "Ofra", text: "8" },
    { value: "Dolev (Talmonim)", text: "16" },
    { value: "Nof Ayalon", text: "16" },
    { value: "Jordan Valley - Gitit, Makura, Ma'ale Ephraim", text: "6.5" },
    { value: "Jordan Valley - Shadmot", text: "16" },
    
  ];