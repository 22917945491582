import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import LoginUser from '../models/loginUser/LoginUser';
import InputTextTable from '../components/InputTextBox';
import Button from '../components/Button';
import { Colors } from '../assets/colors/colors';
import * as RegisterValidator from '../utils/validators/RegisterValidator';
import * as ErrorConstants from '../utils/constants/ErrorConstants'
import UserService from '../requests/UserService'
import InputPasswordTextBox from '../components/InputPasswordTextBox';
import { useParams } from 'react-router-dom';
import Spinner from '../utils/spinner';

export interface ILoginPage { }

const LoginPage: React.FunctionComponent<ILoginPage> = (props) => {
    const navigate = useNavigate();
    const { rideID } = useParams();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isLoginError, setIsLoginError] = useState(false);
    const [loginError, setLoginError] = useState<any>("");
    const [isPressedLoginButton, setIsPressedLoginButton] = useState<boolean>(false)

    const changeUserName = (userName: string) => {
        setUserName(userName);
    }

    const changePassword = (password: string) => {
        setPassword(password);
    }

    const validate = () => {
        const isEmptyUserName = RegisterValidator.ValidateEmpty(userName);
        if (isEmptyUserName) {
            setUserNameError(ErrorConstants.EMPTY_STRING)
        }
        const isEmptyPassword = RegisterValidator.ValidateEmpty(password);
        if (isEmptyPassword) {
            setPasswordError(ErrorConstants.EMPTY_STRING)
        }
        if (!isEmptyUserName && !isEmptyPassword) {
            return true
        }
        return false;
    }

    const onRegisterPressed = () => {
        navigate("/register")
    }

    const onLoginPressed = () => {
        const isValid = validate();
        if (isValid) {
            setIsPressedLoginButton(true)
            setUserNameError("");
            setPasswordError("");
            const user = new LoginUser(userName, password)
            UserService.login(user)
                .then((response: any) => {
                    setIsLoginError(false);
                    setLoginError("");
                    console.log('response', response)
                    if (response.data.isVerified === 0) {
                        setIsPressedLoginButton(false)                       
 setLoginError("עוד לא אושרת בידי המנהל שלך")
                    } else {
                        console.log('response.data.token', response.data.token)
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("email", response.data.email);
                        localStorage.setItem("city", response.data.city)
                        localStorage.setItem("address", response.data.address);
                        localStorage.setItem("rate", response.data.rate)
                        localStorage.setItem("name", response.data.name);
                        localStorage.setItem("phoneNumber", response.data.phoneNumber);
                        localStorage.setItem("age", response.data.age);
                        var imageData = response.data.img.data;
                       // var dataArray = Array.from(imageData as number[]);
                        // Convert the array to a Base64 string
                       // var base64String = btoa(String.fromCharCode.apply(null, dataArray));
                        // Store the Base64 string in local storage
                       const byteArray = new Uint8Array(imageData);
			const blob = new Blob([byteArray], {type: 'image/png'});
			const dataUrl = URL.createObjectURL(blob);
			 localStorage.setItem('imgDataUrl', dataUrl);
			
                        if (rideID != null) {
			    navigate(`/joinRequestsPage/${rideID}`)
			} else {
                             navigate('/homePage')
			}
                    }
                    console.log(response);
                })
                .catch((e: any) => {
                    setIsPressedLoginButton(false)
                    console.log('e', e)
                    setIsLoginError(true);
                    if (e.response.status === 400) {
                        setLoginError("משתמש לא קיים, נא הירשם")
                    }
                    else {
                        if (e.response.status === 403) {
                            setLoginError("סיסמא לא תואמת, נסה שוב")
                        } else {
                            setLoginError("שגיאת שרת, יטופל בהקדם")
                        }
                    }
                });
        } else {
            //not alll fileds are ok
            setIsLoginError(true);
            setLoginError("לא כל השדות מולאו נכון")
        }
    }

    return (
        <div className='wrap-page'>
            <div style={{ paddingBottom: '2rem' }}>
                <div className='h2 bold d-flex-center'>!כיף לראות אותך</div>
            </div>
            <div className='d-flex-center-column' style={{ gap: '10px' }}>
                <InputTextTable headLine="שם משתמש" onChangeValue={changeUserName} errorMessage={userNameError} />
                <InputPasswordTextBox headLine='סיסמא' onChangeValue={(value) => changePassword(value)}
                errorMessage={passwordError} />
            </div>
            <div>
                <div className='h6 error-message' style={{display: isLoginError ? 'flex' : 'none',  backgroundColor: '#C63C47' }}>{loginError}</div>
            </div>
            {isPressedLoginButton ?
            <Spinner/> :
            <div>
                <Button content="התחבר" backgroundColor={Colors.buttons.light} color={Colors.buttons.dark} onClick={onLoginPressed} />
                <Button content="להרשמה" backgroundColor={Colors.buttons.dark} color={Colors.white} onClick={onRegisterPressed} />
            </div>}            
        </div>
    );
};

export default LoginPage;
