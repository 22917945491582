import Button from "../components/Button";
import SelectTextBox from "../components/SelectTextBox";
import { AxiosError } from 'axios';
import { Colors } from '../assets/colors/colors';
import { useState } from 'react'
import FindRideImgPng from '../assets/img/png/find_ride.png';
import FindRideImgWebp from '../assets/img/webp/find_ride.webp';
import IRideProperties from '../models/rideProperties/IRideProperties';
import * as RideConstansts from '../utils/constants/RideConstanst';
import * as ErrorConstants from '../utils/constants/ErrorConstants';
import * as RegisterValidator from '../utils/validators/RegisterValidator';
import { CityOptions } from '../utils/options/CityOptions';
import { ChangeUserProperty, DefaultDate } from '../utils/models.utils';
import RideServices from '../requests/RideServices';
import { useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import ChangeRideDirectionPng from '../assets/img/png/change-ride-direction.png';
import ChangeRideDirectionWebp from '../assets/img/webp/change-ride-direction.webp';
import Spinner from "../utils/spinner";
import "react-datepicker/dist/react-datepicker.css";

export interface ISearchDrivePage { }

const SearchDrivePage: React.FunctionComponent<ISearchDrivePage> = (props) => {

    const [rideProperties, setRideProperties] = useState<IRideProperties>({
        [RideConstansts.EMAIL]: '',
        [RideConstansts.ORIGIN]: 'Ariel',
        [RideConstansts.DESTINATION]: localStorage.getItem('city') || '',
        [RideConstansts.DATE]: new Date(),
        [RideConstansts.NUM_OF_PASSENGERS]: 0,
        [RideConstansts.IMG]: null,
    });

    const [errorMessages, setErrorMessages] = useState({
        [RideConstansts.ORIGIN]: '',
        [RideConstansts.DESTINATION]: '',
        [RideConstansts.DATE]: '',
        [RideConstansts.NUM_OF_PASSENGERS]: '',
    });;

    const [isSearchStarted, setIsSearchStarted] = useState(false);
    const [chosenCity, setChosenCity] = useState(localStorage.getItem('city') || '');

    const [originIsSelect, setOriginIsSelect] = useState(false);
    const [destinationIsSelect, setDestinationIsSelect] = useState(true);

    const navigate = useNavigate()

    const Validator = (): boolean => {
        var isAnyEmpty = false;
        Object.keys(errorMessages).forEach((error) => {
            const isEmpty = RegisterValidator.ValidateEmpty(rideProperties[error]);
            isAnyEmpty = isAnyEmpty || isEmpty;
            isEmpty ? ChangeUserProperty(error, ErrorConstants.EMPTY_STRING, setErrorMessages)
                : ChangeUserProperty(error, '', setErrorMessages);
        });
        RegisterValidator.ValidateEmptyDate(rideProperties[RideConstansts.DATE])
        const isDateEmpty = rideProperties[RideConstansts.DATE]?.getTime() === DefaultDate.getTime() ? true : false;
        isDateEmpty ? ChangeUserProperty(RideConstansts.DATE, ErrorConstants.EMPTY_STRING_DATE, setErrorMessages)
            : ChangeUserProperty(RideConstansts.DATE, '', setErrorMessages);
        return (isDateEmpty || isAnyEmpty) ? false : true
    }

    const findRide = () => {
        const isValid = Validator();
        if (isValid) {
            setIsSearchStarted(true);
            console.log('valid')
            console.log(rideProperties)
            RideServices.get(rideProperties)
                .then((response: any) => {
                    navigate('/ridesResultPage', { state: { rideProperties: response.data.rides } })
                })
                .catch((e: AxiosError) => {
                    const status = e.response?.status;
                    if (status === 401 || status === 403) {
                        navigate('/unauthorizeError')
                    } else {
                        navigate('/error')
                    }
                    setIsSearchStarted(false);
                });
        }
    }

    const changeUserProperty = (key: string, value: any, setState: any) => {
        if (key === RideConstansts.ORIGIN || key === RideConstansts.DESTINATION) {
            setChosenCity(value);
        }
        setState((prevState: any) => ({
            ...prevState,
            [key]: value
        }));
    }

    const changeDirection = () => {
        if (originIsSelect) {
            changeUserProperty(RideConstansts.ORIGIN, 'Ariel', setRideProperties);
            changeUserProperty(RideConstansts.DESTINATION, chosenCity, setRideProperties);
        }
        if (destinationIsSelect) {
            changeUserProperty(RideConstansts.DESTINATION, 'Ariel', setRideProperties);
            changeUserProperty(RideConstansts.ORIGIN, chosenCity, setRideProperties);
        }
        setOriginIsSelect(!originIsSelect);
        setDestinationIsSelect(!destinationIsSelect);
    }

    return (
        <div className="wrap-inside-page">
            <div className="h3 bold">מצא נסיעה</div>
            <picture>
                <source srcSet={FindRideImgWebp} type="image/webp" />
                <img src={FindRideImgPng} alt="Add ride" loading="lazy" style={{ width: '40%' }} />
            </picture>
            <div className='body' style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <SelectTextBox defaultOption={chosenCity}  
                    headLine='נקודת יציאה' onChangeValue={(value) => changeUserProperty(RideConstansts.ORIGIN, value, setRideProperties)}
                    errorMessage={errorMessages[RideConstansts.ORIGIN]} selectOptions={CityOptions} isSelect={originIsSelect} />
                <SelectTextBox
                  defaultOption={chosenCity}
                    headLine={<div className='d-flex-center' style={{ marginBottom: '0.5rem' }}>נקודת הגעה<picture style={{ marginRight: '10rem' }}>
                        <source srcSet={ChangeRideDirectionWebp} type="image/webp" />
                        <img src={ChangeRideDirectionPng} alt="schange ride direction" loading="lazy" onClick={() => changeDirection()} />
                    </picture></div>}
                    onChangeValue={(value) => changeUserProperty(RideConstansts.DESTINATION, value, setRideProperties)}
                    errorMessage={errorMessages[RideConstansts.DESTINATION]} selectOptions={CityOptions} isSelect={destinationIsSelect} />
                <div className='d-flex-center'>
                </div>
                <div className='flex-column' style={{ gap: '1rem' }}>
                    <div>
                        <div className='h5' style={{ direction: 'rtl', marginBottom: '0.5rem' }}>תאריך ושעה</div>
                        <DatePicker
                            selected={rideProperties.date}
                            onChange={(date) => changeUserProperty(RideConstansts.DATE, date, setRideProperties)}
                            showTimeSelect
                            dateFormat="d/MM/Y, H:mm"
                            timeIntervals={15}
                        />
                    </div>
                    {isSearchStarted ? <Spinner/> :
                    <Button content="חפש" backgroundColor={Colors.buttons.dark} color={Colors.white} onClick={findRide} />}
                </div>
            </div>
        </div>
    );
}

export default SearchDrivePage;
