import { Colors } from "../assets/colors/colors";
import { IOptions } from "../utils/options/IOptions";
import AcademicIconPng from '../assets/img/png/academic-icon.png';
import AcademicIconWebp from '../assets/img/webp/academic-icon.webp';

const SelectTextBox = ({ headLine, errorMessage, onChangeValue, selectOptions, isSelect, defaultOption }:
    { headLine: any, errorMessage?: string, onChangeValue: (arg0: string) => void, selectOptions: IOptions[], isSelect?: boolean, defaultOption?: string }) => {

    const submitOption = (e: React.FormEvent<HTMLSelectElement>) => {
        onChangeValue(e.currentTarget.value);
    }

    const findTextOfValue = () => {
        return selectOptions.find(option => option.value === defaultOption)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', direction: 'rtl' }}>
            <div style={{ display: 'flex' }}>
                <div className='h5'>{headLine}</div>
            </div>
            {isSelect ?
                <select
                    style={{
                      //  marginTop: '7px',
                      //  marginBottom: '7px',
                        height: '60px',
                        border: errorMessage ? '1px solid red' : '1px solid white',
                        backgroundColor: "#D9D9D9",
                        borderRadius: '10px',
                        fontSize: '26px',
                        width: '100%',
                        direction: 'rtl',
                        fontFamily: 'Assistant, sans-serif'
                    }}
                    onChange={submitOption}>
                    {defaultOption ? 
                        <option key={defaultOption} defaultValue={defaultOption}>
                            {findTextOfValue()?.text}
			</option> :     
                       <option style={{ display: 'none' }} value=''></option>
			}                   
                        {selectOptions.map((selectOption) => (
                        selectOption.value !== defaultOption &&                        
                         <option key={selectOption.value} value={selectOption.value}>
                            {selectOption.text}
                        </option>
                    ))}
                </select>
                : <div className='h5 bold d-flex-center'
                    style={{  borderRadius: '10px', height: '60px', backgroundColor: Colors.darkBlue, color: Colors.white }}>
                    <picture>
                        <source srcSet={AcademicIconWebp} type="image/webp" />
                        <img src={AcademicIconPng} alt="academic icon" loading="lazy" />
                    </picture>
                    אוניברסיטת אריאל
                </div>}
            <div className="error-text">{errorMessage}</div>
        </div>
    )
}

export default SelectTextBox
