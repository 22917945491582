export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const GENDER = 'gender';
export const AGE = 'age';
export const CITY = 'city';
export const FULL_ADDRESS = 'fullAdress';
export const PHONE_NUMBER = 'phoneNumber';
export const PICTURE = 'picture';
export const STUDENT_APPROVAL = 'studentApproval';
export const HAS_CAR = 'hasCar';
export const CAR_NUMBER = 'carNumber';
export const IS_VERIFIED = 'isVerified';
export const AUTHORIZATION = 'authorization';