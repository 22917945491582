import { AxiosError } from 'axios';
import { Colors } from '../assets/colors/colors';
import contactUsImgPng from '../assets/img/png/contact-us-img.png';
import contactUsImgWebp from '../assets/img/webp/contact-us-img.webp';
import Button from '../components/Button';
import InputTextBox from '../components/InputTextBox';
import IContactUs from '../models/IContactUs';
import WhatsupService from '../requests/WhatsupService';
// import WhatsupService from '../requests/WhatsupService';
import * as ContactUsConstants from '../utils/constants/ContactUsConstants'
import { useState } from 'react';
import SelectTextBox from '../components/SelectTextBox';
import { ContactUsOptions } from '../utils/options/ContactUsOptions';

export interface IContactUsPage { }

const ContactUsPage: React.FunctionComponent<IContactUsPage> = (props) => {

    const [contactUsProperties, setContactUsProperties] = useState<IContactUs>({
        [ContactUsConstants.TYPE]: '',
        [ContactUsConstants.PROBLEM]: '',
    });
    const [isSent, setIsSent] = useState<boolean>(false)

    const changeUserProperty = (key: string, value: any, setState: any) => {
        setState((prevState: any) => ({
            ...prevState,
            [key]: value
        }));
    }

    const sendContactUsForm = () => {
        WhatsupService.contactUs(contactUsProperties.type, contactUsProperties.problem, localStorage.getItem("email") || '')
            .then((response: any) => {
                setIsSent(true);
            })
            .catch((e: AxiosError) => {
                console.log('error', e)
            });
    }

    return (
        <div className='d-flex-center-column' style={{ gap: '0.5rem' }}>
            <div className='h3 bold'>?איך נוכל לעזור</div>
            <picture>
                <source srcSet={contactUsImgWebp} type="image/webp" />
                <img src={contactUsImgPng} alt="contact us" loading="lazy" />
            </picture>
            {isSent ?
            <div>
                <div className='h3 bold' style={{marginTop: '3rem'}}>הטופס נשלח בהצלחה </div>
            </div>
            :
                <div style={{width: '100%'}}>
                     <SelectTextBox headLine='סוג פנייה' onChangeValue={(value) => changeUserProperty(ContactUsConstants.TYPE, value, setContactUsProperties)} 
                        selectOptions={ContactUsOptions} isSelect={true}/>
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <div className='h5' style={{ direction: 'rtl' }}>איך נוכל לעזור?</div>
                        <textarea style={{ marginTop: '7px', marginBottom: '7px', backgroundColor: '#D9D9D9', borderRadius: '10px', fontSize: '23px', direction: 'rtl', border: 'none', width: '100%' }}
                            rows={6} onChange={(value) => changeUserProperty(ContactUsConstants.PROBLEM, value.target.value, setContactUsProperties)} />
                    </div>
                    <Button content="שלח" backgroundColor={Colors.buttons.dark} color='white' onClick={() => sendContactUsForm()} />
                </div>}
        </div>
    )
}

export default ContactUsPage;
