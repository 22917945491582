export const ValidateEmpty = (value: any) : boolean => {
    if (value === '') {
        return true
    }
    return false
}

export const ValidateEmptyDate = (date: Date) : boolean => {
    if (date.getTime() === new Date(2023, 1, 1, 1, 1).getTime()) {
        return true
    }
    return false
}

export const ValidateEmail = (email: string): boolean => {
    if (email.match(/^\S+@\S+\.\S+$/)) {
        return true
    }
    return false
}

export const ValidatePhoneNumber = (phoneNumber: string) : boolean => {
    if (phoneNumber.match(/^\d{10}$/)) {
        return true
    }
    return false
}

export const ValidateDate = (date: string) : boolean => {
    if (date.match(/^(\d{1,2})\/(\d{1,2})\/(\d{2}|\d{4})$/)) {
        return true
    }
    return false
}

export const ValidatePassword = (password: string) : boolean => {
    if (password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
        return true
    }
    return false
}