import CancelRideImgPng from '../assets/img/png/cancel-ride-img.png'
import CancelRideImgWebp from '../assets/img/webp/cancel-ride-img.webp'
import RideType from '../enums/RideType'
import ShowRideProperties from '../models/rideProperties/ShowRideProperties'
import { CityOptions } from '../utils/options/CityOptions'
import RideBox from './RideBox'

const CancelRidePopup = ({ handleClose, rideInfoInsidePopup, rideType }:
    { handleClose: (rideInfoInsidePopup: ShowRideProperties | undefined) => void, rideInfoInsidePopup: ShowRideProperties | undefined , rideType: RideType}) => {

    return (
        <div className="popup-box">
            <div className="popup" style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <span className="close-icon" onClick={() => handleClose(rideInfoInsidePopup)}>x</span> 
                <picture>
                    <source srcSet={CancelRideImgWebp} type="image/webp" />
                    <img src={CancelRideImgPng} alt="cancel ride" loading="lazy"  />
                </picture>
                <div className='h5 bold center-text'>האם אתה בטוח רוצה לבטל את הנסיעה?</div>
                <RideBox
                            currentRide={rideInfoInsidePopup ? rideInfoInsidePopup : new ShowRideProperties()}
                            origin={CityOptions.find(option => option.value === rideInfoInsidePopup!.origin)!.text}
                            destination={CityOptions.find(option => option.value === rideInfoInsidePopup!.destination)!.text}
                            rideType={rideType}
                            buttonText={'בטל'}/>
            </div>
        </div>
    )
}

export default CancelRidePopup