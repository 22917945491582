import { Outlet } from "react-router-dom";
import aboveWavePng from '../assets/img/png/above_wave.png'
import bottomWavePng from '../assets/img/png/bottom_wave.png';
import aboveWaveWebp from '../assets/img/webp/above_wave.webp'
import bottomWaveWebp from '../assets/img/webp/bottom_wave.webp';

const EntryLayout: React.FC = () => {
    return (
        <div className='wrap-app'>
            <picture>
                <source srcSet={aboveWaveWebp} type="image/webp" />
                <img className='wave upWave' src={aboveWavePng} alt="above wave" loading="lazy" />
            </picture>
            <Outlet />
            <picture>
                <source srcSet={bottomWaveWebp} type="image/webp" />
                <img className='wave downWave' src={bottomWavePng} alt="bottom wave" loading="lazy" />
            </picture>
        </div>
    )
}

export default EntryLayout;