import { useEffect, useState } from 'react'
import { AxiosError } from 'axios';
import { useNavigate } from "react-router-dom";
import ShieldApprovePng from "../assets/img/png/shield-approve.png";
import ShieldApproveWebp from "../assets/img/webp/shield-approve.webp";
import Stars from "../components/Stars";
import Toggle from "../components/Toggle";
import Slider from "../components/Slider";
import Button from "../components/Button";
import { Colors } from "../assets/colors/colors";
import { useParams } from 'react-router-dom';
import IRatingParameters from '../models/IRatingParameters';
import * as RateConstant from '../utils/constants/RateConstant'
import RateService from '../requests/RateService';
import UserService from '../requests/UserService';
import ShowRideProperties from '../models/rideProperties/ShowRideProperties';

export interface IAddRate { }

const AddRate: React.FunctionComponent<IAddRate> = (props) => {

    const navigate = useNavigate();
    const { rideID, email, origin, destination } = useParams();
    const [user, setUser] = useState<ShowRideProperties>();
    const [userImageSrc, setUserImageSrc] = useState<string>();

    const [ratingParameters, setRatingParameters] = useState<IRatingParameters>({
        [RateConstant.DRIVER_EMAIL]: email,
        [RateConstant.RIDE_ID]: parseInt(rideID!),
        [RateConstant.SAFTEY]: 0,
        [RateConstant.TIMES]: 0,
        [RateConstant.IS_ANONYMUS]: false,
        [RateConstant.COMMENT]: "",
        [RateConstant.OVERALL_RATE]: 0,
        [RateConstant.DATE]: new Date(),
        [RateConstant.FIRST_NAME]: ''
    })

    console.log('user', user)

    useEffect(() => {
        const driverEmail = email || '';
        UserService.get(driverEmail)
            .then((response: any) => {
                console.log('data', response)
                setUser(response.data[0]);
                setUserImageSrc("data:;base64," + btoa(
                    new Uint8Array(response.data[0].img.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        '',
                    ),
                ))
                const nameWords: string[] = localStorage.getItem("name")?.split(' ') || [''];
                nameWords.pop();
                if (nameWords) {
                    setRatingParameters((prevState) => ({
                        ...prevState,
                        [RateConstant.FIRST_NAME]: (nameWords.join(" ")),
                    }))
                }
            })
            .catch((e: AxiosError) => {
                const status = e.response?.status;
                if (status === 401 || status === 403) {
                    navigate('/unauthorizeError')
                } else {
                    navigate('/error')
                }
            });

    }, [email, navigate]);

    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRatingParameters((prevState) => ({
            ...prevState,
            [RateConstant.COMMENT]: (event.target.value),
        }))
    }

    const addRate = () => {
        console.log('ratingParameters', ratingParameters)
        RateService.create(ratingParameters)
            .then((res: any) => {
                console.log(res)
                window.location.href = `/Payment/${origin}/${destination}`;
            })
            .catch((err: any) => {
                console.log(err)
            })
    }

    return (
        <div className='inner-gray-box'>
            <div>
                <div className='h3 bold' style={{ textAlign: 'center' }}>דרג את הנסיעה</div>
            </div>
            <div>
                <img src={userImageSrc} alt="user" />
            </div>
            <div className="d-flex-center h3" style={{ gap: '0.5rem' }}>
                <picture>
                    <source srcSet={ShieldApproveWebp} type="image/webp" />
                    <img src={ShieldApprovePng} alt="shield approve" loading="lazy" />
                </picture>
                <div> {user?.age}</div>
                <div>{user?.firstName} {user?.lastName}</div>
            </div>
            <Stars numberOfStars={2} />
            <div className="flex-column" style={{ width: '60vw', direction: 'rtl', gap: '0.25rem' }}>
                <div className="d-flex-center" style={{ width: '100vw', justifyContent: 'flex-start', gap: '2.5rem' }}>
                    <div className="h5">דירוג אנונימי</div>
                    <Toggle parameterName={RateConstant.IS_ANONYMUS} setParameter={setRatingParameters} />
                </div>
                <div className="h5">בטיחות</div>
                <div className="whole-width">
                    <Slider parameter={ratingParameters[RateConstant.SAFTEY]} parameterName={RateConstant.SAFTEY} setParameter={setRatingParameters} />
                </div>
                <div className="h5">זמנים</div>
                <div className="whole-width">
                    <Slider parameter={ratingParameters[RateConstant.TIMES]} parameterName={RateConstant.TIMES} setParameter={setRatingParameters} />
                </div>
                <div className="h5">תגובה מילולית:</div>
                <textarea className="whole-width" rows={5} style={{ fontSize: '21px', border: '1px solid black' }} value={ratingParameters[RateConstant.COMMENT]} onChange={handleTextareaChange}></textarea>
            </div>
            <Button content="שלח" backgroundColor={Colors.buttons.dark} color={Colors.white} onClick={addRate} />
        </div>
    )
}
export default AddRate;
