export const ORIGIN = 'origin';
export const DESTINATION = 'destination';
export const DATE = 'date';
export const NUM_OF_PASSENGERS = 'numOfPassengers'
export const EMAIL = 'email';
export const IMG = 'img';
export const FIRST_NAME = 'firstName'
export const LAST_NAME = 'lastName';
export const PHONE_NUMBER = 'phoneNumber';
export const RIDE_ID = 'id';
export const RATE = 'rate';
export const AGE = 'age';