import React from 'react'
import IRideProperties from '../models/rideProperties/IRideProperties';
import * as RideConstansts from '../utils/constants/RideConstanst';

interface StyleObject {
    [key: string]: React.CSSProperties;
}

const CountNumebrs = ({title, count, setCount, onClick}: 
    {title: string, count: number, setCount: React.Dispatch<React.SetStateAction<IRideProperties>>,
        onClick: (key: string, value: any, setState: any) => void}) => {

    const styles: StyleObject = {
        button: {
            backgroundColor: '#D9D9D9',
            border: 'white',
            borderRadius: '30px'
        },
    }

    const changeNumOfPassengers = (mathAction: string) => {
        switch (mathAction) {
            case '-':
                if (count > 0) {
                    onClick(RideConstansts.NUM_OF_PASSENGERS, count - 1, setCount)
                }
                break;
	   case '+':
		if (count < 4) {
                    onClick(RideConstansts.NUM_OF_PASSENGERS, count + 1, setCount)
		}                
		break;
        }
    }

    return (
        <div style={{gap: '1rem'}}>
            <div className='h5 d-flex-center' style={{textAlign: 'center'}}>{title}</div>
            <div className='d-flex-center h3' style={{ gap: '2rem' }}>
                <div onClick={() => changeNumOfPassengers('+')} style={{ ...styles.button, padding: '3px 18px 10px' }}>+</div>
                <div>{count}</div>
                <div onClick={() => changeNumOfPassengers('-')} style={{ ...styles.button, padding: '3px 22px 10px' }}>-</div>
            </div>
        </div>
    )
}

export default CountNumebrs;
