export interface ILargeScreenError { }

const LargeScreenError: React.FunctionComponent<ILargeScreenError> = (props) => {

    return (
        <div>
            אנחנו תומכים כרגע רק בפלאפונים, עמכם הסליחה
        </div>
    )
}

export default LargeScreenError