import ShowRideProperties from "../models/rideProperties/ShowRideProperties";
import ShieldApprovePng from "../assets/img/png/shield-approve.png"
import ShieldApproveWebp from "../assets/img/webp/shield-approve.webp"
import PhoneIconPng from '../assets/img/png/phone-icon.png';
import PhoneIconWebp from '../assets/img/webp/phone-icon.webp';
// import MessageIcon from '../assets/img/message-icon.png';
import UserIconPng from '../assets/img/png/user-icon.png';
import UserIconWebp from '../assets/img/webp/user-icon.webp';
import { CityOptions } from "../utils/options/CityOptions";
import Button from "./Button";
import { Colors } from "../assets/colors/colors";
// import { useNavigate } from "react-router-dom";
import joiningToRideService from "../requests/joiningToRideServices";
import * as RideConstansts from '../utils/constants/RideConstanst';
import { useState } from "react";
import JoinRequestSentImgPng from '../assets/img/png/join-request-sent.png'
import JoinRequestSentImgWebp from '../assets/img/webp/join-request-sent.webp'
import { PriceOptions } from "../utils/options/PriceOption";
import WhatsupService from "../requests/WhatsupService";
// import WhatsupService from "../requests/WhatsupService";

const RideProposition = ({ handleClose, rideInfoInsidePopup }:
    { handleClose: (rideInfoInsidePopup: ShowRideProperties | undefined) => void, rideInfoInsidePopup: ShowRideProperties | undefined }) => {

    // const navigate = useNavigate();
    const userImageSrc = rideInfoInsidePopup === undefined ?
        ''
        : "data:;base64," + btoa(
            new Uint8Array(rideInfoInsidePopup.img.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
            ),
        )
     //   console.log('as', rideInfoInsidePopup)
    const price = rideInfoInsidePopup?.destination === 'Ariel' ? PriceOptions.find(option => option.value === rideInfoInsidePopup?.origin)?.text : PriceOptions.find(option => option.value === rideInfoInsidePopup?.destination)?.text
    const [isAlreadyJoined, setIsAlreadyJioned] = useState<boolean>(false);
    const date = rideInfoInsidePopup?.date || '';
    const dateTime = new Date(date);
    // const timezoneOffsetInMinutes = dateTime.getTimezoneOffset();
    // const timezoneOffsetInHours = Math.abs(timezoneOffsetInMinutes / 60);
    // const time = dateTime.getHours() + timezoneOffsetInHours;
    // const timeString = `${time.toString().padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}`;
    const timezoneOffsetInMinutes = dateTime.getTimezoneOffset();
    const timezoneOffsetInMilliseconds = timezoneOffsetInMinutes * 60 * 1000;//Math.abs(timezoneOffsetInMinutes / 60);
    const dateWithGMTOffset = new Date(dateTime.getTime() - timezoneOffsetInMilliseconds);
    const hours = dateWithGMTOffset.getHours();
    const minutes = dateWithGMTOffset.getMinutes(); // Month is zero-based, so we add 1
    const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    console.log("here", rideInfoInsidePopup)

    const origin = CityOptions.find(option => option.value === rideInfoInsidePopup?.origin)?.text;
    const destination = CityOptions.find(option => option.value === rideInfoInsidePopup?.destination)?.text;
    const blueGrayColor = '#597A99'
  const joinerName = localStorage.getItem('name') + '';


    // const SendMessage = () => {
    //     let driverPhoneNumber = rideInfoInsidePopup?.phoneNumber;
    //     let requestPhoneNumber = localStorage.getItem('phoneNumber');
    //     let message = `You have just recived ride request from ${requestPhoneNumber} that ask to join to the app`

    //     WhatsupService.sendMessage(driverPhoneNumber || '', message)
    // }

    console.log(process.env.REACT_APP_URL)

    const onJoinRidePress = () => {
        if (rideInfoInsidePopup != null) {
            console.log("here", rideInfoInsidePopup)
            joiningToRideService.create(rideInfoInsidePopup[RideConstansts.RIDE_ID])
                .then((response: any) => {
                    console.log('success')
                    setIsAlreadyJioned(true);
                    console.log('rideInfoInsidePopup', rideInfoInsidePopup)
                    WhatsupService.askToJoin(rideInfoInsidePopup.phoneNumber.slice(1),
                                             joinerName,
                                             rideInfoInsidePopup.id);
                    // let driverPhoneNumber = rideInfoInsidePopup?.phoneNumber;
                    // let userPhoneNumber = localStorage.getItem('phoneNumber');
                    // let url = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}joinRequestsPage/${rideInfoInsidePopup.id}`
                    // let message = `You have just recived ride request from ${userPhoneNumber} that ask to join to the app\nYou can see your rides on the Link: ${url}`;
                    // WhatsupService.sendMessage(driverPhoneNumber || '', message)
                    //     .then((res) => {
                    //         navigate("/rideRequestSent")
                    //     })
                    //     .catch((e: Error) => {
                    //         console.log(e);
                    //     })
                    // .catch((e: Error) => {
                    //     console.log(e);
                    // });
                });
        }
    }

    return (
        <div className="popup-box">
            <div className="popup" style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                <span className="close-icon" onClick={() => handleClose(rideInfoInsidePopup)}>x</span>
                {isAlreadyJoined ?
                    <div>
                        <div className='d-flex-center'>
                            <picture>
                                <source srcSet={JoinRequestSentImgWebp} type="image/webp" />
                                <img src={JoinRequestSentImgPng} alt="join reauset" loading="lazy" style={{ borderRadius: '30px' }} />
                            </picture>
                        </div>
                        <div className="d-flex-center h4 bold" style={{marginBottom: '2rem'}}>בקשתך נקלטה כעת</div>
                        <div className="center-text h5">הודעה תשלח ברגע שהצטרפותך תאושר על ידי המסיע</div>
                    </div>
                    :
                    <div className="d-flex-center-column" style={{ gap: '0.5rem' }}>
                        <div className="d-flex-center h3">
                            הצעת נסיעה
                        </div>
                        <div className="d-flex-center">
                            <img src={userImageSrc} alt='user' className='userImage' />
                        </div>
                        <div className="d-flex-center h4" style={{ gap: '0.5rem' }}>
                            <picture>
                                <source srcSet={ShieldApproveWebp} type="image/webp" />
                                <img src={ShieldApprovePng} alt="shield approve" loading="lazy" />
                            </picture>
                            <div> {rideInfoInsidePopup?.age}</div>
                            <div>{rideInfoInsidePopup?.firstName}</div>
                        </div>
                        <div className="d-flex-center" style={{ gap: '0.5rem' }}>
                            <a href="tel:+1234567890">
                                <picture>
                                    <source srcSet={PhoneIconWebp} type="image/webp" />
                                    <img src={PhoneIconPng} alt="phone" loading="lazy" />
                                </picture>
                            </a>
                            {/* <img src={MessageIcon} alt='message' onClick={SendMessage} /> */}
                            <picture>
                                <source srcSet={UserIconWebp} type="image/webp" />
                                <img src={UserIconPng} alt="user" loading="lazy" />
                            </picture>
                        </div>
                        <div className="d-flex-center-column h5">
                            <div>נקודת יציאה</div>
                            <div className="bold">{origin}</div>
                        </div>
                        <div className="d-flex-center-column h5">
                            <div>נקודת הגעה</div>
                            <div className="bold">{destination}</div>
                        </div>

                        <div className="times h5" style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div>
                                <div>שעת יציאה</div>
                                <div className="d-flex-center bold">{timeString}</div>
                            </div>
                        </div>
                        <div className="d-flex-center-column h5" >
                            {/* <div style={{ backgroundColor: blueGrayColor, color: 'white', borderRadius: '20px', padding: '0.25rem 4rem' }}>
                                עלות {price} ש"ח
                            </div> */}
                            <Button content={`עלות ${price} שח`} backgroundColor={Colors.buttons.light} color={Colors.darkBlue} medium={true}
                                onClick={function (): void {
                                    
                                }} />
                            <Button content={"להצטרף"} backgroundColor={Colors.buttons.dark} color={Colors.white} medium={true}
                                onClick={function (): void {
                                    onJoinRidePress();
                                }} />
                        </div>
                    </div>}
            </div>
        </div>
    );
}

export default RideProposition;
