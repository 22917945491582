import EmptyRidesImgPng from '../assets/img/png/empty-rides.png'
import EmptyRidesImgWebp from '../assets/img/webp/empty-rides.webp'

const EmptyRides = () => {

    return (
        <div className='d-flex-center-column linearGradientBackground' style={{ padding: '0.5rem' }}>
            <picture>
                <source srcSet={EmptyRidesImgWebp} type="image/webp" />
                <img src={EmptyRidesImgPng} alt="empty rides" loading="lazy" />
            </picture>
            <div className='h3 bold'>יעד זה אינו זמין כעת</div>
            <div className='h4'>נסה במועד מאוחר יותר</div>
        </div>
    )
}

export default EmptyRides