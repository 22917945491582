import { AxiosError } from 'axios';
import PaymentService from '../requests/PaymentService';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PriceOptions } from '../utils/options/PriceOption';

export interface IPaymentPage { }

const PaymentPage: React.FunctionComponent<IPaymentPage> = (props) => {

    const navigate = useNavigate();
    const { origin, destination } = useParams();
    const ws = new WebSocket('wss://trempist-stage.org.il:5002/');

    ws.onopen = () => {
        console.log('client WebSocket connection opened');
    };
    ws.onclose = () => {
        console.log('WebSocket connection closed');
    };

    ws.onmessage = (response) => {
        const { data } = response
        const json = JSON.parse(data);
        const serverLowProfileCode = json.lowprofilecode;
console.log('json', json)
        console.log('serverLowProfileCode', serverLowProfileCode);
        console.log('lowProfileCode', lowProfileCode);      
  if (serverLowProfileCode === lowProfileCode) {
            PaymentService.validate(serverLowProfileCode)
                .then((response: any) => {
                    console.log('step 2 response', response.data)
                    const responseCode = getParamByKey(response.data, 'ResponseCode')
                    if (responseCode === '0') {
                        PaymentService.addPayment(1)
                        ws.close();
                        navigate('/Goodbye/')
                    }
                })
                .catch((e: AxiosError) => {
                    console.log('error', e)
                    ws.close();
                });
        }
    }

    const getParamByKey = (responseString: string, keyToSearch: string) => {
        const keyValuePairs = responseString.split('&');
        let result = '';
        for (const keyValue of keyValuePairs) {
            const [key, value] = keyValue.split('=');
            if (key === keyToSearch) {
                result = value;
                break;
            }
        }
        return result;
    }

    const [url, setUrl] = useState<string>();
    const [lowProfileCode, setLowProfileCode] = useState<string>();
   //var lowProfileCode = '';

    useEffect(() => {
        console.log(destination, origin)
        const price = destination === 'Ariel' ? PriceOptions.find(option => option.value === origin)?.text : PriceOptions.find(option => option.value === destination)?.text
        console.log('price', price)
        PaymentService.getPaymentParams(price)
            .then((response: any) => {
                setLowProfileCode(getParamByKey(response.data, 'LowProfileCode'));
                let local = getParamByKey(response.data, 'url');
                setUrl(decodeURIComponent(local).replace(/^https:/, ''));
            })
            .catch((e: AxiosError) => {
		alert('error')
	alert(e);
            })
    }, [])

    return (
        <div style={{ height: '100%' }}>
            {url &&
                <iframe allow="payment"  width='100%' height='100%' id='paymentIframe' title='paymentIframeb'
                    src={url} />}
        </div>
    )
}

export default PaymentPage;
