import IUserProperties from "./IUserProperties";

export default class UserProperties implements IUserProperties {
    email!: string;
    password!: string;
    firstName!: string;
    lastName!: string;
    phoneNumber!: string;
    age!: number;
    gender!: string;
    city!: string;
    fullAdress!: string;
    picture: any;
    studentApproval: any;
    hasCar!: number;
    carNumber!: string;
    isVerified!: number;
    authorization!: number;
    public constructor( init?:Partial<UserProperties>) {
        Object.assign(this, init)
    }
    [key: string]: string | number;
}