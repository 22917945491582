import Button from "../components/Button";
import { Colors } from '../assets/colors/colors';
import LinseDotsImgPng from '../assets/img/png/line-dots.png';
import FullStarPng from '../assets/img/png/full-star.png'
import LinseDotsImgWebp from '../assets/img/webp/line-dots.webp';
import FullStarWebp from '../assets/img/webp/full-star.webp'
import { daysOfWeek } from "../utils/DaysWeek";
import ShowRideProperties from "../models/rideProperties/ShowRideProperties";
import RideType from "../enums/RideType";
import joiningToRideService from "../requests/joiningToRideServices";
import rideService from '../requests/RideServices';
import JoiningState from "../models/joiningState/JoiningState";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import WhatsupService from "../requests/WhatsupService";
import { CityOptions } from "../utils/options/CityOptions";

const RideBox = ({ origin, destination, currentRide, togglePopup, rideType, buttonText }:
    {
        origin: string, destination: string, currentRide: ShowRideProperties, buttonText: string
        togglePopup?: (cuurentRide: ShowRideProperties) => void, rideType: RideType
    }) => {

const driverName = localStorage.getItem('name') || '';

    const userImageSrc = "data:;base64," + btoa(
        new Uint8Array(currentRide.img.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
        ),
    );

    const navigate = useNavigate();
    const [isCanceled, setIscanceled] = useState<Boolean>(false);
    console.log('currentRide', currentRide)

    const handleRequest = (isAproved: boolean, isDeclined: boolean, status: string) => {
        const joiningState = new JoiningState(currentRide.rideID, currentRide.email, isAproved, isDeclined, false);
        joiningToRideService.update(joiningState)
            .then(response => {
                const fromText = CityOptions.find(option => option.value === currentRide.origin)!.text;
                const toText = CityOptions.find(option => option.value === currentRide.destination)!.text;
                const date = new Date(currentRide.date);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedDate = `${day}/${month}`;
                WhatsupService.statusJoinToRideUpdated(currentRide.phoneNumber.slice(1),
                    isAproved ? "אושרה" : "סורבה", "מ" + fromText + " ל" + toText, formattedDate, driverName)
                     .then(() => {
                         window.location.reload();
                     })
                     .catch((e: Error) => {
                         console.log(e);
                     })
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const cancelRideDriver = () => {
        rideService.remove(currentRide.id)
            .then(response => {
                console.log('went well')
                const fromText = CityOptions.find(option => option.value === currentRide.origin)!.text;
                const toText = CityOptions.find(option => option.value === currentRide.destination)!.text;
                const date = new Date(currentRide.date);
                const day = date.getDate().toString();
                const month = (date.getMonth() + 1).toString();
                const name = localStorage.getItem("name") || '';
                const phoneNumber = localStorage.getItem("phoneNumber") || '';
                WhatsupService.cancelRideDriver(phoneNumber.slice(1), name, fromText, toText, month, day);
                setIscanceled(true)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const cancelRideJoiner = () => {
        console.log('currentRide', currentRide)
        const joiningState = new JoiningState(currentRide.rideID, currentRide.email, false, false, true);
        joiningToRideService.update(joiningState)
            .then(response => {
                const fromText = CityOptions.find(option => option.value === currentRide.origin)!.text;
                const toText = CityOptions.find(option => option.value === currentRide.destination)!.text;
                const date = new Date(currentRide.date);
                const day = date.getDate().toString();
                const month = (date.getMonth() + 1).toString();
                const phoneNumber: string = localStorage.getItem('phoneNumber')?.slice(1) ?? '';
                const name =  localStorage.getItem('name') ?? '';
                WhatsupService.cancelRideJoiner(phoneNumber, name, fromText, toText, month, day)
                        .then((res) => {
                            setIscanceled(true);
                            window.location.reload();
                        })
                        .catch((e: Error) => {
                            console.log(e);
                        })
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const redirectToProfilePage = () => {
        navigate('/driverProfile', { state: { name: currentRide.firstName, age: currentRide.age, img: currentRide.img } });
    }

    const noop = () => {}

    const shouldHideButton = rideType === RideType.Request || rideType === RideType.CancelDriver || rideType === RideType.CancelJoiner

    const dateTime = new Date(currentRide.date);
    const dayWeek = daysOfWeek[dateTime.getDay()];
    const timezoneOffsetInMinutes = dateTime.getTimezoneOffset();
    const timezoneOffsetInMilliseconds = timezoneOffsetInMinutes * 60 * 1000;//Math.abs(timezoneOffsetInMinutes / 60);
    const dateWithGMTOffset = new Date(dateTime.getTime() - timezoneOffsetInMilliseconds);
    const day = dateWithGMTOffset.getDate();
    const month = dateWithGMTOffset.getMonth() + 1;
    const hours = dateWithGMTOffset.getHours();
    const minutes = dateWithGMTOffset.getMinutes(); // Month is zero-based, so we add 1
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    return (
        <div className="rideBox d-flex-center-column linearGradientBackground" style={{
            direction: 'rtl', padding: '0.5rem 1rem 0 1rem', borderRadius: '25px'
        }}>
            <div className="d-flex-center">
                <div className="text" style={{ width: '80%' }}>
                    <div className="date_and_time" style={{ display: 'flex', gap: '0.5rem' }}>
                        <div className="date h5 bold blue-text">{dayWeek}' {formattedDate},</div>
                        <div className="time h5 blue-text">{formattedTime}</div>
                    </div>
                    <div className="route" style={{ display: 'flex', gap: '0.25rem' }}>
                        <div className='picture' style={{ display: 'flex', alignItems: 'center' }}>
                            <picture>
                                <source srcSet={LinseDotsImgWebp} type="image/webp" />
                                <img src={LinseDotsImgPng} alt="LinseDots" loading="lazy" />
                            </picture>
                        </div>
                        <div className='orig-dest'>
                            <div className="origin h5 blue-text">{origin}</div>
                            <div className="destination h5 blue-text">{destination}</div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '20%' }}>
                    <img src={userImageSrc} alt="user" style={{ width: '100%', backgroundColor: 'black', borderRadius: '10px', height: '8vh' }}
  onClick={(rideType === RideType.Request || rideType === RideType.Offer) ? redirectToProfilePage : noop} />
                    <div className='d-flex-center' style={{ gap: '0.5rem' }}>
                        <picture>
                            <source srcSet={FullStarWebp} type="image/webp" />
                            <img src={FullStarPng} alt="full star" loading="lazy" style={{ width: '25%' }} />
                        </picture>
                        <div className="h8 bold">{currentRide.rate}</div>
                    </div>
                    <Button content={buttonText} hide={shouldHideButton} small={true} backgroundColor={Colors.buttons.dark} color={Colors.white} onClick={() => togglePopup && togglePopup(currentRide)} />
                </div>
            </div>
            {rideType === RideType.Request &&
                <div className="d-flex-center" style={{ gap: '0.5rem', position: 'relative', top: "1rem" }}>
                    <Button content="אשר" small={true} backgroundColor={Colors.buttons.dark} color={Colors.white} onClick={() => handleRequest(true, false, "approved")} />
                    <Button content="סרב" small={true} backgroundColor={Colors.buttons.error} color={Colors.white} onClick={() => handleRequest(false, true, "declined")} />
                </div>}
            {rideType === RideType.CancelDriver && !isCanceled &&
                <div className="d-flex-center" style={{ gap: '0.5rem', position: 'relative', top: "1rem" }}>
                    <Button content="לבטל" small={true} backgroundColor={Colors.buttons.dark} color={Colors.white} onClick={() => cancelRideDriver()} />
                    <Button content="לא לבטל" small={true} backgroundColor={Colors.buttons.error} color={Colors.white} onClick={() => window.location.reload()} />
                </div>}
            {rideType === RideType.CancelJoiner && !isCanceled &&
                <div className="d-flex-center" style={{ gap: '0.5rem', position: 'relative', top: "1rem" }}>
                    <Button content="לבטל" small={true} backgroundColor={Colors.buttons.dark} color={Colors.white} onClick={() => cancelRideJoiner()} />
                    <Button content="לא לבטל" small={true} backgroundColor={Colors.buttons.error} color={Colors.white} onClick={() => window.location.reload()} />
                </div>}
            {isCanceled &&
                <div>
                    <div className='h5 bold'> הנסיעה בוטלה</div>
                </div>}
        </div>
    )
}

export default RideBox;
