import IRatingParameters from "../models/IRatingParameters";

const Slider = ({parameter, parameterName, setParameter}: 
    {parameter: number, parameterName: string, setParameter: React.Dispatch<React.SetStateAction<IRatingParameters>>}) => {

    const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParameter((prevState) => ({
            ...prevState,
            [parameterName]: (parseInt(e.target.value)),
          }))
      };

    return (
        <div className="slidecontainer whole-width" style={{direction: 'ltr'}}>
  <         input className='whole-width' type="range" min="1" max="10" value={parameter} onChange={(event) => changeValue(event)}/>
        </div>
    );
}


export default Slider;