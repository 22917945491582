import { useState } from 'react'
import { AxiosError } from 'axios';
import { useNavigate } from "react-router-dom";
import AddRideImgPng from '../assets/img/png/add-ride-photo.png'
import SuccesfullyAddedRidePng from '../assets/img/png/succesfully_added_rided.png'
import AddRideImgWebp from '../assets/img/webp/add-ride-photo.webp'
import SuccesfullyAddedRideWebp from '../assets/img/webp/succesfully_added_rided.webp'
import ChangeRideDirectionPng from '../assets/img/png/change-ride-direction.png';
import ChangeRideDirectionWebp from '../assets/img/webp/change-ride-direction.webp';
import SelectTextBox from '../components/SelectTextBox'
import IRideProperties from '../models/rideProperties/IRideProperties'
import * as RideConstansts from '../utils/constants/RideConstanst';
import * as ErrorConstants from '../utils/constants/ErrorConstants';
import * as RegisterValidator from '../utils/validators/RegisterValidator';
import CountNumebrs from '../components/CountNumbers';
import { CityOptions } from '../utils/options/CityOptions';
import Button from '../components/Button';
import { Colors } from '../assets/colors/colors';
import { DefaultDate } from '../utils/models.utils'
import RideServices from '../requests/RideServices'
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";

export interface IAddDrivePage { }

const AddDrivePage: React.FunctionComponent<IAddDrivePage> = (props) => {
    const navigate = useNavigate();

    const [rideProperties, setRideProperties] = useState<IRideProperties>({
        [RideConstansts.ORIGIN]: 'Ariel',
        [RideConstansts.DESTINATION]: localStorage.getItem('city') || '',
        [RideConstansts.DATE]: new Date(),
        [RideConstansts.NUM_OF_PASSENGERS]: 0,
    });

    const [errorMessages, setErrorMessages] = useState({
        [RideConstansts.ORIGIN]: '',
        [RideConstansts.DESTINATION]: '',
        [RideConstansts.DATE]: '',
        [RideConstansts.NUM_OF_PASSENGERS]: '',
    });

    const [chosenCity, setChosenCity] = useState(localStorage.getItem('city') || '');

    const [wasPressed, setWasPressed] = useState(false);
    const [originIsSelect, setOriginIsSelect] = useState(false);
    const [destinationIsSelect, setDestinationIsSelect] = useState(true);

    const Validator = (): boolean => {
        var isAnyEmpty = false;
        RegisterValidator.ValidateEmptyDate(rideProperties[RideConstansts.DATE])
        const isDateEmpty = rideProperties[RideConstansts.DATE]?.getTime() === DefaultDate.getTime() ? true : false;
        isDateEmpty ? changeUserProperty(RideConstansts.DATE, ErrorConstants.EMPTY_STRING_DATE, setErrorMessages)
            : changeUserProperty(RideConstansts.DATE, '', setErrorMessages);
        return (isDateEmpty || isAnyEmpty) ? false : true
    }

    const AddRide = () => {
        const isValid = Validator();
        console.log('newUser', rideProperties)
        if (isValid) {
            //const newUser = new RegisterUser(userProperties);
            RideServices.create(rideProperties)
                .then((response: any) => {
                    console.log(response)
                    setWasPressed(true);
                })
                .catch((e: AxiosError) => {
                    const status = e.response?.status;
                    if (status === 401 || status === 403) {
                        navigate('/unauthorizeError')
                    } else {
                        navigate('/error')
                    }
                });
        }
    }


    const changeUserProperty = (key: string, value: any, setState: any) => {
      if (key === RideConstansts.ORIGIN || key === RideConstansts.DESTINATION) {
            console.log('value', value)
            setChosenCity(value);
        }
        setState((prevState: any) => ({
            ...prevState,
            [key]: value
        }));
    }

    const changeDirection = () => {
        if (originIsSelect) {
            changeUserProperty(RideConstansts.ORIGIN, 'Ariel', setRideProperties);
            changeUserProperty(RideConstansts.DESTINATION, chosenCity, setRideProperties);
        }
        if (destinationIsSelect) {
            changeUserProperty(RideConstansts.DESTINATION, 'Ariel', setRideProperties);
            changeUserProperty(RideConstansts.ORIGIN, chosenCity, setRideProperties);
        }
        setOriginIsSelect(!originIsSelect);
        setDestinationIsSelect(!destinationIsSelect);
    }

    return (
        <div className="wrap-inside-page">
            <div className="h3 bold">?לאן אתה נוסע</div>
            <picture>
                <source srcSet={AddRideImgWebp} type="image/webp" />
                <img src={AddRideImgPng} alt="Add ride img" loading="lazy" style={{ width: '40%' }} />
            </picture>
            <div className='body' style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <SelectTextBox defaultOption={chosenCity}  headLine='נקודת יציאה' onChangeValue={(value) => changeUserProperty(RideConstansts.ORIGIN, value, setRideProperties)}
                    errorMessage={errorMessages[RideConstansts.ORIGIN]} selectOptions={CityOptions} isSelect={originIsSelect} />
                <SelectTextBox defaultOption={chosenCity}
                    headLine= {<div className='d-flex-center' style={{marginBottom: '0.5rem'}}>נקודת הגעה<picture style={{marginRight: '10rem'}}>
                        <source srcSet={ChangeRideDirectionWebp} type="image/webp" />
                        <img src={ChangeRideDirectionPng} alt="schange ride direction" loading="lazy" onClick={() => changeDirection()} />
                    </picture></div>}
                    onChangeValue={(value) => changeUserProperty(RideConstansts.DESTINATION, value, setRideProperties)}
                    errorMessage={errorMessages[RideConstansts.DESTINATION]} selectOptions={CityOptions} isSelect={destinationIsSelect} />
                <div className='d-flex-center'>
                </div>
                {wasPressed ?
                    <picture>
                        <source srcSet={SuccesfullyAddedRideWebp} type="image/webp" />
                        <img src={SuccesfullyAddedRidePng} alt="succesfully added ride" loading="lazy" />
                    </picture>
                    :
                    <div className='flex-column' style={{ gap: '1rem' }}>
                        <CountNumebrs title="?כמות הנוסעים היכולים להצטרף" count={rideProperties[RideConstansts.NUM_OF_PASSENGERS]} setCount={setRideProperties}
                            onClick={changeUserProperty} />
                        <div>
                            <div className='h5' style={{ direction: 'rtl', marginBottom: '0.5rem' }}>תאריך ושעה</div>
                            <DatePicker
                                selected={rideProperties.date}
                                onChange={(date) => changeUserProperty(RideConstansts.DATE, date, setRideProperties)}
                                showTimeSelect
                                dateFormat="d/MM/Y, H:mm"
                                timeIntervals={15}
                            />
                        </div>
                        <Button content="הוסף" backgroundColor={Colors.buttons.dark} color={Colors.white} onClick={AddRide} />
                    </div>
                }
            </div>
        </div>
    )
}

export default AddDrivePage
