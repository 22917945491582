import IRideProperties from "./IRideProperties";
import * as RideConstansts from '../../utils/constants/RideConstanst';
import { IBufferObject } from "../IBufferObject";

export default class ShowRideProperties implements IRideProperties {
    [key: string]: any;
    [RideConstansts.ORIGIN]! : string;
    [RideConstansts.DESTINATION]!: string;
    [RideConstansts.DATE]!: Date;
    [RideConstansts.NUM_OF_PASSENGERS]!: number;
    [RideConstansts.IMG]!: IBufferObject;
    [RideConstansts.AGE]!: number;
    [RideConstansts.FIRST_NAME]!: string;
    [RideConstansts.LAST_NAME]!: string;
    [RideConstansts.PHONE_NUMBER]!: string;
    [RideConstansts.EMAIL]!: string;
    [RideConstansts.RIDE_ID]!: number;
    [RideConstansts.RATE]!: number;
}