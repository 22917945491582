import { useState } from 'react';
import { Outlet } from "react-router-dom";
import MenuIcon from "../components/MenuIcon";
import BottomBar from "../components/BottomBar";
// import SideBar from "../components/SideBar";

export default function AppLayout() {

    const [isOpen, setIsOpen] = useState(false);
    const ToggleSidebar = () => {
        isOpen === true ? setIsOpen(false) : setIsOpen(true);
    }

    return (
        <div>
            {/* <SideBar isOpen={isOpen} setIsOpen={setIsOpen} ToggleSidebar={ToggleSidebar} /> */}
            <MenuIcon onClick={ToggleSidebar} />
            <div style={{ overflow: 'auto', height: '81vh' }}>
                <div style={{ width: '90vw', margin: 'auto', height: '100%' }}>
                    <Outlet />
                </div>
            </div>
            <BottomBar />
        </div >
    )
}
