const   InputTextBox = ({headLine, errorMessage, placeholder, onChangeValue,} : 
    {headLine: string, placeholder?: string,  errorMessage?: string, onChangeValue: (arg0: string) => void }) => {

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChangeValue(e.target.value);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', direction: 'rtl', width: '100%' }}>
            <div style={{ display: 'flex'}}>
                <div className='h5'>{headLine}</div>
            </div>
            <input className='input-text-box'
                style={{border: errorMessage ? '1px solid red' : '1px solid white',}}
                    placeholder={placeholder}
                    onChange={handleChangeInput}>
            </input>
            <div className="error-text" style={{contain: 'inline-size'}}>{errorMessage}</div>
        </div>
    )
}

export default InputTextBox