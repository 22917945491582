import JoiningState from "../models/joiningState/JoiningState";
import ShowRideProperties from "../models/rideProperties/ShowRideProperties";
import getAxiosInstance from "./httpCommon";

const baseURL = "/api/joinings"
const axios = getAxiosInstance(); // Get the Axios instance

const get = async (rideID: number) => {
  return await axios.get<Array<ShowRideProperties>>(`${baseURL}`,
    {params: {
        rideID: rideID,
      }
    });
};

const create = async (rideID: number) => {
  return await axios.post<number>(`${baseURL}`, { "rideID": rideID, "incrementPassengersParam": "-1" });
};

const update = async (JoiningState: JoiningState) => {
  return await axios.put<number>(`${baseURL}`, {"JoiningState" : JoiningState, "incrementPassengersParam": "+1"});
};

const joiningToRideService = {
  get,
  create,
  update,
};

export default joiningToRideService;
