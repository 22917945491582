import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import WelocomePage from './pages/WelocomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import RegisterConfirmationPage from './pages/RegisterConfirmationPage';
import AddDrivePage from './pages/AddDrivePage';
import SearchDrivePage from './pages/SearchDrivePage';
import RidesResultPage from './pages/RidesResultPage';
import HomePage from './pages/HomePage';
import JoinRequestsPage from './pages/JoinRequstsPage';
import MyRidesPage from './pages/MyRidesPage';
import MyRJoiningidesPage from './pages/MyJoiningRIdePage';
import ContactUsPage from './pages/ContactUsPage';
import DriverProfilePage from './pages/DriverProfilePage';
import GoodbyePage from './pages/GoodbyePage';
import AddRate from './pages/AddRate';
import AppLayout from './layouts/AppLayout';
import EntryLayout from './layouts/EntryLayout';
import ScreenSize from './enums/ScreenSize';
import MyProfilePage from './pages/MyProfilePage';
import LargeScreenError from './pages/LargeScreenError';
import PaymentPage from './pages/PaymentPage';
import { Context } from './utils/Context'
import './App.css';
import ErrorPage from './pages/ErrorPage';
import UnauthorizeError from './pages/UnauthorizeErrorPage';

export interface IApplicationProps { }

const App: React.FunctionComponent<IApplicationProps> = (props) => {
  const screenHeight = window.innerHeight < 700 ? ScreenSize.Small : ScreenSize.Regular
  const screenWidth = window.innerWidth;// < 700 ? ScreenSize.Small : ScreenSize.Regular
  const navigate = useNavigate()

  useEffect(() => {
    if (screenWidth > 500) {
      navigate('/largeScreenError'); // Navigate to a different page for small screens
    }
  }, [screenWidth, navigate]);

  return (
    // <BrowserRouter>
      <Context.Provider value={screenHeight}>
        <Routes>
          <Route element={<EntryLayout />} >
            <Route path="/largeScreenError" element={<LargeScreenError />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/unauthorizeError/:rideID?" element={<UnauthorizeError />} />
            <Route path="/" element={<WelocomePage />} />
            <Route path="/login/:rideID?" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/registerConfirmationPage" element={<RegisterConfirmationPage />} />
          </Route>
          <Route element={<AppLayout />} >
            <Route path="/homePage" element={<HomePage />} />
            <Route path="/addDrive" element={<AddDrivePage />} />
            <Route path="/searchDrive" element={<SearchDrivePage />} />
            <Route path="/ridesResultPage" element={<RidesResultPage />} />
            <Route path="/joinRequestsPage/:rideID" element={<JoinRequestsPage />} />
            <Route path="/AddRate/:rideID/:email/:origin/:destination" element={<AddRate />} />
            <Route path="/MyProfile" element={<MyProfilePage />} />
            <Route path="/MyRides" element={<MyRidesPage />} />
            <Route path="/MyJoiningRides" element={<MyRJoiningidesPage />} />
            <Route path="/ContactUs" element={<ContactUsPage />} />
            <Route path="/DriverProfile" element={<DriverProfilePage />} />
            <Route path="/Payment/:origin/:destination" element={<PaymentPage />} />
            <Route path="/Goodbye" element={<GoodbyePage />} />
          </Route>
        </Routes>
      </Context.Provider>
    // </BrowserRouter>
  );
};

export default App;
