import { useState } from 'react'
import DecideShowRideImgPng from '../assets/img/png/decide-show-ride-img.png'
import DecideShowRideImgWebp from '../assets/img/webp/decide-show-ride-img.webp'
import Button from './Button'
import { Colors } from '../assets/colors/colors'

const DecideShowRideTypePopup = ({ handleClose }: { handleClose: () => void }) => {

    const [isJoiner, setIsJoiner] = useState<boolean>(true)
    const [isDriver, setIsDriver] = useState<boolean>(false)

    const handleRadioButtonClick = (isJoinerClicked: boolean, isDriverClicked: boolean) => {
        setIsJoiner(isJoinerClicked);
        setIsDriver(isDriverClicked)
    }
    
    const redirectToRidesPage = () => {
        isJoiner && (window.location.href = window.location.origin + '/MyJoiningRides')
        isDriver && (window.location.href = window.location.origin + '/MyRides')
    }

    return (
        <div className="popup-box">
            <div className="popup flex-column" style={{ alignItems: 'center', gap: '0.5rem' }}>
                <span className="close-icon" onClick={() => handleClose()}>x</span>
                <div className="h3 bold">הגדר את עצמך</div>
                <picture>
                    <source srcSet={DecideShowRideImgWebp} type="image/webp" />
                    <img src={DecideShowRideImgPng} alt="decide show ride type" loading="lazy"  />
                </picture>
                <div className='flex-column full-width' style={{alignItems: 'flex-end', gap: '1rem', marginBottom: '2rem'}}>
                    <div className="h5 bold" style={{marginTop: '1rem', marginBottom: '0.5rem'}}>?מהו תפקידך</div>
                    <div className='d-flex'>
                        <div className='h6 bold'>נוסע</div>
                        <input type="radio" name="joiner" checked={isJoiner} onChange={() => handleRadioButtonClick(true, false)} />
                    </div>
                    <div className='d-flex'>
                        <div className='h6 bold'>מסיע</div>
                        <input type="radio" name="driver" checked={isDriver} onChange={() => handleRadioButtonClick(false, true)} />
                    </div>
                </div>
                <Button content={"המשך"} backgroundColor={Colors.buttons.dark} color={Colors.white}
                    onClick={() => redirectToRidesPage()} />
            </div>
        </div>
    )
}

export default DecideShowRideTypePopup;