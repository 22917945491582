import IJoiningState from "./IJoiningState";
import * as JoiningConstants from '../../utils/constants/JoiningConstants';

class JoiningState implements IJoiningState {
    [key: string]: any;
    [JoiningConstants.RIDE_ID]!: number;
    [JoiningConstants.EMAIL]!: string;
    [JoiningConstants.IS_APPROVED]!: boolean;
    [JoiningConstants.IS_DECLINED]!: boolean;
    [JoiningConstants.IS_APPROVED]!: boolean;
    [JoiningConstants.IS_CANCELED]!: boolean;

    constructor(
        rideID: number,
        email: string,
        isApproved: boolean,
        isDeclined: boolean,
        isCanceled: boolean
      ) {
        this[JoiningConstants.RIDE_ID] = rideID;
        this[JoiningConstants.EMAIL] = email;
        this[JoiningConstants.IS_APPROVED] =  isApproved;
        this[JoiningConstants.IS_DECLINED] = isDeclined;
        this[JoiningConstants.IS_CANCELED] = isCanceled;
      }
}

export default JoiningState;