import axios from 'axios';

const getAxiosInstance = () => {
  const instance = axios.create({
    baseURL: 'https://trempist-stage.org.il/',
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });

  // Add a request interceptor to update headers before each request
  instance.interceptors.request.use(
    (config) => {
      config.headers.Authorization = localStorage.getItem('token');
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default getAxiosInstance;

