import getAxiosInstance from "./httpCommon";

const baseURL = 'api/payment';
const axios = getAxiosInstance();

const validate = async (lowProfileCode: string) => {
    return await axios.get('https://secure.cardcom.solutions/Interface/BillGoldGetLowProfileIndicator.aspx', {
        params: {
            terminalnumber: process.env.REACT_APP_PAYMENT_TERMIAN_NUMBER,
            username: process.env.REACT_APP_PAYMENT_USER_NAME,
            lowprofilecode: lowProfileCode
        }
    });
}

const getPaymentParams = async (price: string | undefined) => {
    return await axios.post(`https://secure.cardcom.solutions/Interface/LowProfile.aspx?codepage=65001&
    Operation=1&
    TerminalNumber=${process.env.REACT_APP_PAYMENT_TERMIAN_NUMBER}&
    UserName=${process.env.REACT_APP_PAYMENT_USER_NAME}&
    SumToBill=${price}&
    CoinID=1&
    Language=he&
    ProductName=item1
    &APILevel=10
    &SuccessRedirectUrl=https://secure.cardcom.solutions/SuccessAndFailDealPage/Success.aspx&
    ErrorRedirectUrl=https://secure.cardcom.solutions/SuccessAndFailDealPage/Fail.aspx&
    IndicatorUrl=https://webhook.site/trempistStage&
    ReturnValue=ReturnValue1234&
    AutoRedirect=false`);
}

const addPayment = async (payment: number) => {
    return await axios.post<boolean>(baseURL, {'currentPayment': payment});
    // return await http.post(baseUrl, {
    //     params: {
    //         currentPayment: payment,
    //     }
    // });
}

const RateService = {
    validate,
    getPaymentParams,
    addPayment
};

export default RateService;
