import getAxiosInstance from "./httpCommon";
import IRegisterCustomer from "../models/reigsterCustomer/IRegisterUser";
import ILoginUser from "../models/loginUser/ILoginUser";
import IUserProperties from "../models/IUserProperties";

const baseURL = "/api/users"
const axios = getAxiosInstance();

const get = async (email: string) => {
  return await axios.get<IRegisterCustomer>(`${baseURL}`,
    {
      params: {
        email: email,
      }
    }
  );
}

const login = async (user: ILoginUser) => {
  return await axios.post<IUserProperties>(`${baseURL}/login`, user);
};

const create = async (user: IUserProperties) => {
  console.log('late user', user)
  const formData = new FormData();
  formData.append('email', user.email);
  formData.append('password', user.password);
  formData.append('firstName', user.firstName);
  formData.append('lastName', user.lastName);
  formData.append('phoneNumber', user.phoneNumber);
 // formData.append('age', user.age.toString());
  formData.append('gender', user.gender);
  formData.append('city', user.city);
  formData.append('address', user.fullAdress);
  formData.append('picture', user.picture);
  formData.append('studentApproval', user.studentApproval);
  formData.append('hasCar', user.hasCar.toString());
  formData.append('carNumber', user.carNumber);
  formData.append('isVerified', user.isVerified.toString());
  formData.append('authorization', user.authorization.toString());
  console.log('formDATA', formData.entries())
  return await axios.post<FormData>(`${baseURL}/register`, formData);
};

const update = async (email: string, user: IRegisterCustomer) => {
  return await axios.put<IRegisterCustomer>(`${baseURL}${email}`, user);
};

const remove = async (email: string) => {
  return await axios.delete<IRegisterCustomer>(`${baseURL}${email}}`);
};

const removeAll = async () => {
  return await axios.delete<IRegisterCustomer>(`${baseURL}`);
};

const TutorialService = {
  get,
  login,
  create,
  update,
  remove,
  removeAll,
};

export default TutorialService;
