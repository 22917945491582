import IRideProperties from "./IRideProperties";
import * as RideConstants from '../../utils/constants/RideConstanst';

class RideProperties implements IRideProperties {
    [key: string]: any;
    [RideConstants.ORIGIN]! : string;
    [RideConstants.DESTINATION]!: string;
    [RideConstants.DATE]!: Date;
    [RideConstants.NUM_OF_PASSENGERS]!: number;

    constructor(
        origin: string,
        destination: string,
        date: Date,
        numOfPassengers: number
      ) {
        this[RideConstants.ORIGIN] = origin;
        this[RideConstants.DESTINATION] = destination;
        this[RideConstants.DATE] = date;
        this[RideConstants.NUM_OF_PASSENGERS] = numOfPassengers;
      }

}
export default RideProperties