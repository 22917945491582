import { IOptions } from "./IOptions";

export const CityOptions: IOptions[] = [
  { "value": "Avnie Hefetz", "text": "אבני חפץ" },
  { "value": "Oranit", "text": "אורנית" },
  { "value": "Ariel", "text": "אריאל" },
  { "value": "Ashdod", "text": "אשדוד" },
  { "value": "Ashkelon", "text": "אשקלון" },
  { "value": "Beer Sheva (and Surroundings)", "text": "באר שבע (הסביבה)" },
  { "value": "Bikat Auno", "text": "בקעת אונו" },
  { "value": "Jordan Valley - Gitit, Makura, Ma'ale Ephraim", "text": "בקעת הירדן - גיתית, מכורה, מעלה אפריים" },
  { "value": "Jordan Valley - Shadmot", "text": "בקעת הירדן - שדמות, מכולה" },
  { "value": "Bet El", "text": "בת אל" },
  { "value": "Bat Yam", "text": "בת ים" },
  { "value": "Beit She'an", "text": "בית שאן" },
  { "value": "Beit Shemesh", "text": "בית שמש" },
  { "value": "Giv'at Shmuel", "text": "גבעת שמואל" },
  { "value": "Giv'atayim", "text": "גבעתיים" },
  { "value": "Gedera", "text": "גדרה" },
  { "value": "Gush Etzion", "text": "גוש עציון" },
  { "value": "Gan Yavne and Surroundings", "text": "גן יבנה והסביבה" },
  { "value": "Dolev (Talmonim)", "text": "דולב (טלמונים)" },
  { "value": "Har Bracha", "text": "הר ברכה" },
  { "value": "Herzliya", "text": "הרצליה" },
  { "value": "Zikhron Ya'akov / Carmel Coast", "text": "זכרון יעקב / חוף הכרמל" },
  { "value": "Hadera", "text": "חדרה" },
  { "value": "Holon", "text": "חולון" },
  { "value": "Heyfa", "text": "חיפה" },
  { "value": "Harish", "text": "חריש" },
  { "value": "Hashmonaim", "text": "חשמונאים" },
  { "value": "Tveria", "text": "טבריה" },
  { "value": "Ykneam", "text": "יקנעם" },
  { "value": "Jerusalem", "text": "ירושלים" },
  { "value": "Kfar Yona", "text": "כפר יונה" },
  { "value": "Kfar Saba", "text": "כפר סבא" },
  { "value": "Lod", "text": "לוד" },
  { "value": "Metar (Har Hevron)", "text": "מיתר (הר חברון)" },
  { "value": "Modi'in", "text": "מודיעין" },
  { "value": "Carmiel", "text": "משגב/כרמיאל" },
  { "value": "Nahria", "text": "נהריה" },
  { "value": "Nof Ayalon", "text": "נוף איילון" },
  { "value": "Nes Ziona", "text": "נס ציונה" },
  { "value": "Netivot", "text": "נתיבות" },
  { "value": "Netanya", "text": "נתניה" },
  { "value": "Ofra", "text": "עפרה" },
  { "value": "Ali", "text": "עלי" },
  { "value": "Petah Tikva", "text": "פתח תקווה" },
  { "value": "Zfat", "text": "צפת" },
  { "value": "Tzofim", "text": "צופים" },
  { "value": "Kdumim", "text": "קדומים" },
  { "value": "Kadima-Zoran", "text": "קדימה צורן" },
  { "value": "Kraiot", "text": "קריות" },
  { "value": "Kiriat Aono", "text": "קריית אונו" },
  { "value": "Kiryat Arba", "text": "קריית ארבע" },
  { "value": "Kiryat Gat", "text": "קריית גת " },
  { "value": "Kiryat Shmuna", "text": "קריית שמונה" },
  { "value": "Rishon LeZion", "text": "ראשון לציון" },
  { "value": "Rehovot", "text": "רחובות" },
  { "value": "RamatGan", "text": "רמת גן" },
  { "value": "Ramat Hagolan Korzi", "text": "רמת הגולן - כורסי" },
  { "value": "Ramat Hagolan Zemah", "text": "רמת הגולן - צמח" },
  { "value": "Ramat Hagolan Kazarin", "text": "רמת הגולן - קצרין" },
  { "value": "Ramlah", "text": "רמלה" },
  { "value": "Ramat Hasharon", "text": "רמת השרון" },
  { "value": "Raanana", "text": "רעננה" },
  { "value": "Shadmot", "text": "שדמות" },
  { "value": "Sderot", "text": "שדרות" },
  { "value": "Shiloh", "text": "שילה" },
  { "value": "Shaarey Tikva", "text": "שערי תקווה" },
  { "value": "Tel Aviv", "text": "תל אביב" }
];
