import React, { useRef, useState } from 'react';

const InputFileTextBox = ({ headLine, errorMessage, onChangeImg }: 
    { headLine: string, errorMessage: string,  onChangeImg: (arg0: any) => void }) => {

    const hiddenFileInput = useRef<any>();
    const [textInside, setTextInside] = useState('צרף תמונה')

    const handleClick = (even: any) => {
        hiddenFileInput.current.click();
    };

    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        if (event.target.files[0].name) {
            setTextInside(`${event.target.files[0].name} צורפה בהצלחה`)
            onChangeImg(fileUploaded);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column',  direction: 'rtl' }}>
            <div style={{ display: 'flex' }}>
                <div className='h5'>{headLine}</div>
            </div>
            <div className='d-flex-center input-text-box' 
                style={{
                    border: errorMessage ? '1px solid red' : '1px solid white',
                }}
                onClick={handleClick}>
                    {textInside}
            </div>
            <input type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
            />
        <div className="error-text">{errorMessage}</div>
        </div>
    )
}

export default InputFileTextBox