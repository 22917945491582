import { Colors } from '../assets/colors/colors'
import UnauthorizeImgPng from '../assets/img/png/unauthorize-img.png'
import UnauthorizeImgWebp from '../assets/img/webp/unauthorize-img.webp'
import Button from '../components/Button'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

export interface IUnauthorizeErrorPage { }

const UnauthorizeErrorPage: React.FunctionComponent<IUnauthorizeErrorPage> = (props) => {
    
    const navigate = useNavigate();
    const { rideID } = useParams();


    return (
        <div className='d-flex-center-column' style={{gap: '0.5rem'}}>
            <div className='h3 bold'>אינך מחובר</div> 
            <div className='h5 center-text'>אנא התחבר לחשבון בכדי להמשיך</div> 
            <Button content="להתחברות" backgroundColor={Colors.buttons.dark} color={Colors.white} onClick={() => navigate(`/login/${rideID}`)} />
            <picture>
                <source srcSet={UnauthorizeImgWebp} type="image/webp" />
                <img src={UnauthorizeImgPng} alt="unauthorize" loading="lazy" />
            </picture>
        </div>
    )
}

export default UnauthorizeErrorPage;
