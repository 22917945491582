import { useLocation } from 'react-router-dom';
import ShowRideProperties from "../models/rideProperties/ShowRideProperties";
import Rides from "../components/Rides";
import RideType from '../enums/RideType';
import { CityOptions } from '../utils/options/CityOptions';

export interface IRidesResultPage { }

const RidesResultPage: React.FunctionComponent<IRidesResultPage> = (props) => {

    const { state } = useLocation();
    const rides: Array<ShowRideProperties> = (state.rideProperties);
    console.log('rides', rides)
    const destinationText = rides.length === 0 ? 'אין נסיעות' : 
                        `נסיעות הבאות ל${CityOptions.find(option => option.value === rides[0].destination)!.text}`

    console.log('rides', rides)

    return (
        <div className="wrap-inside-page">
            <div className="h3 bold">{destinationText}</div>
           {rides && <Rides rides={rides} rideType={RideType.Offer} isAddRide={true}/>}
        </div>
    )

}

export default RidesResultPage