import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from "react-router-dom";
import ShowRideProperties from "../models/rideProperties/ShowRideProperties";
import RideServices from '../requests/RideServices';
import MyJoiningRidesImagePng from '../assets/img/png/my_joining_ride_img.png';
import MyJoiningRidesImageWebp from '../assets/img/webp/my_joining_ride_img.webp';
import Rides from '../components/Rides';
import RideType from '../enums/RideType';
import Button from '../components/Button';
import { Colors } from '../assets/colors/colors';
import DeleteIdentityType from '../enums/DeleteIdentityType';
import Spinner from '../utils/spinner';

export interface IMyJoiningRidesPage { }

const MyJoiningRidesPage: React.FunctionComponent<IMyJoiningRidesPage> = (props) => {

    const navigate = useNavigate(); 
    const [rides, setRides] = useState<Array<ShowRideProperties>>(new Array<ShowRideProperties>());
    const [isRidesLoaded, setIsRidesLoaded] = useState(false);

    useEffect(() => {
        const email = localStorage.getItem('email') || "";
        RideServices.getUserJoiningRides(email)
            .then((response: any) => {
                response.data.rides.forEach((ride: ShowRideProperties) => {
                    ride.rate = Number(localStorage.getItem('rate'));
                })
                setRides(response.data.rides);
                console.log(response.data)
            })
            .catch((e: AxiosError) => {
                const status = e.response?.status;
                if (status === 401 || status === 403) {
                    navigate('/unauthorizeError')
                } else {
                    navigate('/error')
                }
            })
            .finally(() => {
                setIsRidesLoaded(true);
            });
    }, [navigate]);

    console.log('rides', rides[0])

    const finishRide = () => {
        window.location.href = `/AddRate/${rides[0].rideID}/${rides[0].email}/${rides[0].origin}/${rides[0].destination}`;
    }

    return (
        <div className='d-flex-center-column'>
            <div className='h5 bold'>?הגעת ליעד</div>
            <picture>
                <source srcSet={MyJoiningRidesImageWebp} type="image/webp" />
                <img src={MyJoiningRidesImagePng} alt="my joining rides" loading="lazy" />
            </picture>
            {isRidesLoaded ?
            <div>
            {rides.length > 0 ?
                <div>
                    <Rides rides={rides} rideType={RideType.Offer} deleteOption={DeleteIdentityType.joiner} />
                    <div className='d-flex-center' style={{ width: '90%', justifyContent: 'space-between' }}>
                        <Button content={"סיימתי נסיעה"} backgroundColor={Colors.buttons.dark} color={Colors.white} medium={true} onClick={finishRide} />
                    </div>
                </div> :
                <div className='h5 bold' style={{marginTop: '3rem'}}>אין לך נסיעות עתידיות</div>}
            </div> : <Spinner/>}    
    </div>
    )
}

export default MyJoiningRidesPage;
