import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from "react-router-dom";
import Stars from '../components/Stars';
import CommentBox from '../components/CommentBox';
import ApprovedCommunity from '../components/ApprovedCommunity';
import RateService from '../requests/RateService';
import IRatingParameters from '../models/IRatingParameters';
import {useLocation} from 'react-router-dom';

export interface IDriverProfilePage { }

const DriverProfilePage: React.FunctionComponent<IDriverProfilePage> = (props) => {
    
    const navigate = useNavigate();
    const [rateProperties, setRateProperties] = useState<Array<IRatingParameters>>();
    const [averageRate, setAverageRate] =  useState<number>(0);
    const location = useLocation();

    const userImageSrc = "data:;base64," + btoa(
        new Uint8Array(location.state.img.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
        ),
    );

    useEffect(() => {
        const email = localStorage.getItem('email') || '';
        RateService.get(email)
            .then((response: any) => {
                console.log(response.data)
                setRateProperties(response.data);
		let averageRate = 0;
		response.data.forEach((rate: any) => {
			averageRate += rate.rating;
		})
		averageRate = averageRate / response.data.length / 2;
		setAverageRate(averageRate)
            })
            .catch((e: AxiosError) => {
                const status = e.response?.status;
                if (status === 401 || status === 403) {
                    navigate('/unauthorizeError')
                } else {
                    navigate('/error')
                }
            });
    }, [navigate]);

    return (
        <div className='d-flex-center-column' style={{ margin: 'auto', width: '85vw' }}>
            <img src={userImageSrc} alt='driver' style={{borderRadius: '10px'}}  width={'84px'} height={'84px'} />
            <div className='d-flex-center' style={{ gap: '0.5rem' }}>
                <div className='h5 bold'>,{location.state.name}</div>
            </div>
            <div className='d-flex-center' style={{ gap: '0.5rem', marginBottom: '1rem' }}>
                <Stars numberOfStars={averageRate} />
                <div className='d-flex-center-column'>
                    <div className='h5 bold'>{rateProperties?.length}</div>
                    <div className='h8 bold'>דירוגים</div>
                </div>
            </div>
            <ApprovedCommunity />
            <div className='d-flex-justify-end h5 bold' style={{ margin: '1.5rem 0', width: '100%' }}>תגובות</div>
            <div className='d-flex-center-column full-width' style={{gap: '0.5rem', paddingBottom: '1rem'}}>
                {rateProperties && rateProperties.map((rate, index) => {
                    return <CommentBox key={index} name={rate.isAnonymus ? 'אנונימי' : rate.firstName} date={rate.date} rate={rate.overallRate} comment={rate.comment} />
                })}
            </div>
        </div>
    )
}

export default DriverProfilePage;
