import React, { useState } from 'react';
import reigsterImgPng from '../assets/img/png/reister_img.png'
import reigsterImgWebp from '../assets/img/webp/reister_img.webp'
import InputTextBox from '../components/InputTextBox';
import InputFileTextBox from '../components/InputFileTextBox';
import InputPasswordTextBox from '../components/InputPasswordTextBox';
import { useNavigate } from 'react-router-dom'
import SelectTextBox from '../components/SelectTextBox';
import * as UserConstants from '../utils/constants/UserConstants'
import * as ErrorConstants from '../utils/constants/ErrorConstants'
import Button from '../components/Button';
import { Colors } from '../assets/colors/colors';
import UserService from '../requests/UserService'
import IUserProperties from '../models/IUserProperties';
import * as RegisterValidator from '../utils/validators/RegisterValidator';
import { CityOptions } from '../utils/options/CityOptions';
import Toggle from '../components/Toggle';
import { GenderOptions } from '../utils/options/GenderOptions';
import UserProperties from '../models/UserProperties';
import Spinner from '../utils/spinner';

export interface IRegisterPage { }

const RegisterPage: React.FunctionComponent<IRegisterPage> = (props) => {

    const [userProperties, setUserProperties] = useState<IUserProperties>({
        [UserConstants.FIRST_NAME]: '',
        [UserConstants.LAST_NAME]: '',
        [UserConstants.EMAIL]: '',
        [UserConstants.PASSWORD]: '',
        [UserConstants.GENDER]: '',
        [UserConstants.AGE]: 0,
        [UserConstants.CITY]: '',
        [UserConstants.FULL_ADDRESS]: '',
        [UserConstants.PHONE_NUMBER]: '',
        [UserConstants.PICTURE]: '',
        [UserConstants.STUDENT_APPROVAL]: '',
        [UserConstants.HAS_CAR]: 0,
        [UserConstants.CAR_NUMBER]: '',
        [UserConstants.IS_VERIFIED]: 0,
        [UserConstants.AUTHORIZATION]: 1,
    });

    const [errorMessages, setErrorMessages] = useState({
        [UserConstants.FIRST_NAME]: '',
        [UserConstants.LAST_NAME]: '',
        [UserConstants.PASSWORD]: '',
        [UserConstants.EMAIL]: '',
        [UserConstants.GENDER]: '',
        [UserConstants.CITY]: '',
        [UserConstants.FULL_ADDRESS]: '',
        [UserConstants.PHONE_NUMBER]: '',
        [UserConstants.PICTURE]: '',
        [UserConstants.STUDENT_APPROVAL]: '',
    })

    const [pageErrorMessage, setPageErrorMessage] = useState('')
    const [isRegisterPressed, setIsRegisterPressed] = useState(false)
    const navigate = useNavigate()

    const changeUserProperty = (key: string, value: any, setState: any) => {
        setState((prevState: any) => ({
            ...prevState,
            [key]: value
        }));
    }

    const Validator = (): boolean => {
        var isAnyEmpty = false;
        Object.keys(errorMessages).forEach((error) => {
            const isEmpty = RegisterValidator.ValidateEmpty(userProperties[error]);
            isAnyEmpty = isAnyEmpty || isEmpty;
            isEmpty ? changeUserProperty(error, ErrorConstants.EMPTY_STRING, setErrorMessages)
                : changeUserProperty(error, '', setErrorMessages);
        });
        const isValidPassword = RegisterValidator.ValidatePassword(userProperties[UserConstants.PASSWORD]);
        isValidPassword ? changeUserProperty(UserConstants.PASSWORD, '', setErrorMessages) :
            changeUserProperty(UserConstants.PASSWORD, ErrorConstants.INVALID_PASSWORD, setErrorMessages);

        const isValidEmail = RegisterValidator.ValidateEmail(userProperties[UserConstants.EMAIL]);
        isValidEmail ? changeUserProperty(UserConstants.EMAIL, '', setErrorMessages) :
            changeUserProperty(UserConstants.EMAIL, ErrorConstants.INVALID_EMAIL, setErrorMessages);

        const isValidPhoneNumber = RegisterValidator.ValidatePhoneNumber(userProperties[UserConstants.PHONE_NUMBER]);
        isValidPhoneNumber ? changeUserProperty(UserConstants.PHONE_NUMBER, '', setErrorMessages) :
            changeUserProperty(UserConstants.PHONE_NUMBER, ErrorConstants.INVALID_PHONE_NUMBER, setErrorMessages);

        if (isValidEmail && isValidPhoneNumber && !isAnyEmpty) {
            return true;
        }
        return false;

    }

    const Register = () => {
        console.log(userProperties)
        const isValid = Validator();
        if (isValid) {
            setIsRegisterPressed(true);
            setPageErrorMessage('');
            console.log(userProperties.picture)
            const newUser = new UserProperties(userProperties);
            console.log('newUser', newUser)
            UserService.create(newUser)
                .then((response: any) => {
                    setPageErrorMessage('here');
                    navigate('/registerConfirmationPage')
                })
                .catch((e: any) => {
                    setIsRegisterPressed(false);
                    setPageErrorMessage(e.response.data.message)
                    console.log(e);
                });
        } else {
            setPageErrorMessage(ErrorConstants.INVALID_FILDES);
        }
    }

    return (
        <div className='wrap-page'>
            <div className='flex-column' style={{ width: '85vw', margin: 'auto', gap: '1rem' }}>
                <picture>
                    <source srcSet={reigsterImgWebp} type="image/webp" />
                    <img src={reigsterImgPng} alt="register img" loading="lazy" />
                </picture>
                <div className='h2 bold d-flex-center'>הקהילה מתרחבת</div>
                <InputTextBox headLine='שם פרטי' onChangeValue={(value) => changeUserProperty(UserConstants.FIRST_NAME, value, setUserProperties)}
                    errorMessage={errorMessages[UserConstants.FIRST_NAME]} />
                <InputTextBox headLine='שם משפחה' onChangeValue={(value) => changeUserProperty(UserConstants.LAST_NAME, value, setUserProperties)}
                    errorMessage={errorMessages[UserConstants.LAST_NAME]} />
                <InputTextBox headLine='אימייל' onChangeValue={(value) => changeUserProperty(UserConstants.EMAIL, value, setUserProperties)}
                    errorMessage={errorMessages[UserConstants.EMAIL]} />
                <InputPasswordTextBox headLine='סיסמא' onChangeValue={(value) => changeUserProperty(UserConstants.PASSWORD, value, setUserProperties)}
                    errorMessage={errorMessages[UserConstants.PASSWORD]} />
                <SelectTextBox headLine='מין' onChangeValue={(value) => changeUserProperty(UserConstants.GENDER, value, setUserProperties)}
                    errorMessage={errorMessages[UserConstants.GENDER]} selectOptions={GenderOptions} isSelect={true} />
                {/* <InputTextBox headLine='גיל' onChangeValue={(value) => changeUserProperty(UserConstants.AGE, value, setUserProperties)}
                /> */}
                <SelectTextBox headLine='קהילה' onChangeValue={(value) => changeUserProperty(UserConstants.CITY, value, setUserProperties)}
                    errorMessage={errorMessages[UserConstants.CITY]} selectOptions={CityOptions} isSelect={true} />
                <InputTextBox headLine='כתובת' onChangeValue={(value) => changeUserProperty(UserConstants.FULL_ADDRESS, value, setUserProperties)}
                    errorMessage={errorMessages[UserConstants.FULL_ADDRESS]} />
                <InputTextBox headLine='נייד' onChangeValue={(value) => changeUserProperty(UserConstants.PHONE_NUMBER, value, setUserProperties)}
                    errorMessage={errorMessages[UserConstants.PHONE_NUMBER]} />
                <InputFileTextBox headLine='תמונה' onChangeImg={(value) => changeUserProperty(UserConstants.PICTURE, value, setUserProperties)}
                    errorMessage={errorMessages[UserConstants.PICTURE]} />
                <InputFileTextBox headLine='אישור לימודים' onChangeImg={(value) => changeUserProperty(UserConstants.STUDENT_APPROVAL, value, setUserProperties)}
                    errorMessage={errorMessages[UserConstants.STUDENT_APPROVAL]} />
                <div className="d-flex-center" style={{ justifyContent: 'space-between', direction: 'rtl' }}>
                    <div className="h5">יש לך רכב?</div>
                    <Toggle parameterName={UserConstants.HAS_CAR} setParameter={setUserProperties} />
                </div>
                <InputTextBox headLine='מספר רכב' onChangeValue={(value) => changeUserProperty(UserConstants.CAR_NUMBER, value, setUserProperties)}
                />
                {isRegisterPressed ?
                <Spinner /> :
                <div className='d-flex-center-column'>
                    <Button content="להרשמה" backgroundColor={Colors.buttons.dark} color={Colors.white} onClick={Register} />
                    <div className="error-text">{pageErrorMessage}</div>
                </div>}
            </div>
        </div>
    );
};

export default RegisterPage;
