import RegisterConfirmationImgPng from '../assets/img/png/RegisterConfirmationImg.png';
import RegisterConfirmationImgWebp from '../assets/img/webp/RegisterConfirmationImg.webp';

export interface IRegisterConfirmationPage { }

const RegisterConfirmationPage: React.FunctionComponent<IRegisterConfirmationPage> = (props) => {

    return (
        <div className='wrap-page d-flex-center' style={{textAlign: 'center', gap: '20px'}}>
            <picture>
                <source srcSet={RegisterConfirmationImgWebp} type="image/webp" />
                <img src={RegisterConfirmationImgPng} alt="RegisterConfirmationImg" style={{width: '70%', marginBottom: '10px'}} loading="lazy" />
            </picture>
            <div className='h1 bold d-flex-center'>!הרשמתך בוצעה בהצלחה</div>
            <div className='h4 bold d-flex-center'>נחזור אליך בהקדם</div>
        </div>
    );
};

export default RegisterConfirmationPage;