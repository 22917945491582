import React, { useState } from 'react';

const InputPasswordTextBox = ({ headLine, errorMessage, placeholder, onChangeValue, }:
    { headLine: string, placeholder?: string, errorMessage?: string, onChangeValue: (arg0: string) => void }) => {

    const [isShownPassword, setIsShownPassword] = useState<boolean>(false);

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChangeValue(e.target.value);
    }

    const handleChangedShownPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsShownPassword(e.target.checked);
    }

    return (
        <div className='flex-column full-width' style={{direction: 'rtl', marginBottom: '0.5rem' }}>
            <div style={{ display: 'flex' }}>
                <div className='h5'>{headLine}</div>
            </div>
            <input className='input-text-box'
                style={{
                    border: errorMessage ? '1px solid red' : '1px solid white',
                }}
                type={isShownPassword ? "text" : "password"}
                placeholder={placeholder}
                onChange={handleChangeInput}
            >
            </input>
            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                <label htmlFor="checkbox" className="h5">הצג סיסמא</label>
                <input className='checkbox-input'
                    id="checkbox"
                    type="checkbox"
                    checked={isShownPassword}
                    onChange={handleChangedShownPassword}
                />
            </div>
            <div className="error-text" style={{ contain: 'inline-size', marginTop: '5px' }}>{errorMessage}</div>
        </div>
    )
}

export default InputPasswordTextBox