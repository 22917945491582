import React from 'react';

export interface ISpinner {}

const Spinner: React.FunctionComponent<ISpinner> = (props) => {
    const loaderStyle: React.CSSProperties = {
        border: '10px solid #f3f3f3',
        borderTop: '10px solid #3498db',
        borderRadius: '50%',
        width: '80px',
        height: '80px',
        animation: 'spin 1s linear infinite'
    };

    return (
        <div className="d-flex-center">
            <div style={loaderStyle}></div>
        </div>
    );
};

const style = document.createElement('style');
style.textContent = `
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;
document.head.append(style);

export default Spinner;
