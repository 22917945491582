import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from "react-router-dom";
import ShowRideProperties from "../models/rideProperties/ShowRideProperties";
import RideServices from '../requests/RideServices';
import MyRidesImagePng from '../assets/img/png/my-rides-img.png';
import MyRidesImageWebp from '../assets/img/webp/my-rides-img.webp';
import Rides from '../components/Rides';
import RideType from '../enums/RideType';
import Button from '../components/Button';
import { Colors } from '../assets/colors/colors';
import DeleteIdentityType from '../enums/DeleteIdentityType';
import joiningToRideService from '../requests/joiningToRideServices';
import WhatsupService from '../requests/WhatsupService';
import { CityOptions } from '../utils/options/CityOptions';
import Spinner from '../utils/spinner';
// import WhatsupService from '../requests/WhatsupService';

export interface IMyRidesPage { }

const MyRidesPage: React.FunctionComponent<IMyRidesPage> = (props) => {

    const navigate = useNavigate();
    const [rides, setRides] = useState<Array<ShowRideProperties>>(new Array<ShowRideProperties>());
    const [isRidesLoaded, setIsRidesLoaded] = useState(false);

    useEffect(() => {
        const email = localStorage.getItem('email') || "";
        RideServices.getUserRides(email)
            .then((response: any) => {
                response.data.rides.forEach((ride: ShowRideProperties) => {
                    ride.rate = Number(localStorage.getItem('rate'));
                })
                setRides(response.data.rides);
                console.log(response.data)
            })
            .catch((e: AxiosError) => {
                const status = e.response?.status;
                if (status === 401 || status === 403) {
                    navigate('/unauthorizeError')
                } else {
                    navigate('/error')
                }
            })
            .finally(() => {
                setIsRidesLoaded(true);
            });
    }, [navigate]);

    const StartRide = () => {
        joiningToRideService.get(Number(rides[0].id))
            .then((response: any) => {
                response.data.forEach((ride: ShowRideProperties) => {
                    const fromText = CityOptions.find(option => option.value === ride.origin)!.text;
                    const toText = CityOptions.find(option => option.value === ride.destination)!.text;
                    WhatsupService.rideStarted(ride.phoneNumber, ride.firstName + " " + ride.lastName, fromText, toText);
                })
                setRides(rides.slice(1));
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    return (
        <div className='d-flex-center-column'>
            <div className='h3 bold'>הנסיעות שלי</div>
            <picture>
                <source srcSet={MyRidesImageWebp} type="image/webp" />
                <img src={MyRidesImagePng} alt="my rides" loading="lazy" />
            </picture>
            {isRidesLoaded ?
            <div>
            {rides.length > 0  ?
            <div>
                <Rides rides={rides} rideType={RideType.Offer} deleteOption={DeleteIdentityType.Driver} />
                <div className='d-flex-center' style={{ width: '90%' }}>
                    <Button content={"התחלתי נסיעה"} backgroundColor={Colors.buttons.dark} color={Colors.white} medium={true} onClick={() => StartRide()} />
                </div>
            </div> :
            <div className='h5 bold' style={{marginTop: '3rem'}}>אין לך נסיעות עתידיות</div>}
            </div> : <Spinner/>}       
 </div>
    )
}

export default MyRidesPage;
