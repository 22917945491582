const MenuIcon = ({onClick}: {onClick: () => void}) => {

    const styles = {
        menuLine: {
          width: '35px',
          height: '2px',
          backgroundColor: 'black',
          margin: '3px 0',
        },
      };
      
    return (
    <div className="menu-icon" 
      style={{justifyContent: 'center', marginLeft: '1rem', display: 'flex', flexDirection: 'column', height: '2vh'}}
       onClick={onClick}>
        <div style={styles.menuLine}/>
        <div style={styles.menuLine}/>
        <div style={styles.menuLine}/>
    </div>
    )
}

export default MenuIcon
