import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import ShowRideProperties from "../models/rideProperties/ShowRideProperties";
import RideProperties from "../models/rideProperties/RideProperties";
import RideServices from '../requests/RideServices';
import { CityOptions } from '../utils/options/CityOptions';
import { useNavigate } from "react-router-dom";
import Rides from '../components/Rides';
import CircleDotsImgPng from '../assets/img/png/dots-inside-circle.png'
import CircleDotsImgWebp from '../assets/img/webp/dots-inside-circle.webp'
import { Colors } from '../assets/colors/colors';
import Button from '../components/Button';
import RideType from '../enums/RideType';
import Spinner from '../utils/spinner';

export interface IHomePage { }

const HomePage: React.FunctionComponent<IHomePage> = (props) => {
    const navigate = useNavigate();
    const [communityRides, setCommunityRides] = useState<Array<ShowRideProperties>>(new Array<ShowRideProperties>());
    const [homeRides, setHomeRides] = useState<Array<ShowRideProperties>>(new Array<ShowRideProperties>());
    const [isCommunityRidesLoaded, setIsCommunityRidesLoaded] = useState<boolean>(false);
    const [isHomeRidesLoaded, setIsHomeRidesLoaded] = useState<boolean>(false);
    const livesCity = CityOptions.find(option => option.value === localStorage.getItem("city"))!.text;
    const imgDataUrl = localStorage.getItem('imgDataUrl');

    const addRide = () => {
        navigate("/addDrive")
    }

    const openRidesPage = (rideProperties: Array<ShowRideProperties>) => {
        navigate('/ridesResultPage', { state: { rideProperties: rideProperties } })
    }

    useEffect(() => {
        const livesCity = CityOptions.find(option => option.value === localStorage.getItem("city"))!.value;
        const university = "Ariel";
        const communityRidepropeits = new RideProperties(
            livesCity, university, new Date(), 0
        )
        RideServices.get(communityRidepropeits)
            .then((response: any) => {
                setCommunityRides(response.data.rides.slice(0, 2));
            })
            .catch((e: AxiosError) => {
                const status = e.response?.status;
                if (status === 401 || status === 403) {
                    navigate('/unauthorizeError')
                } else {
                    navigate('/error')
                }
            })
            .finally(() => {
                setIsCommunityRidesLoaded(true);
            });

        const homeRidepropeits = new RideProperties(
            university, livesCity, new Date(), 0
        )
        RideServices.get(homeRidepropeits)
            .then((response: any) => {
                console.log(response)
                setHomeRides(response.data.rides.slice(0, 2));
            })
            .catch((e: AxiosError) => {
                const status = e.response?.status;
                if (status === 401 || status === 403) {
                    navigate('/unauthorizeError')
                } else {
                    navigate('/error')
                }
            })
            .finally(() => {
                setIsHomeRidesLoaded(true);
            });

    }, [navigate]);

    return (
        <div style={{ height: '81vh' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '1rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <div className='h5'>,היי</div>
                    <div className='h5 bold'>{localStorage.getItem("name")}</div>
                </div>
                <img src={imgDataUrl ? imgDataUrl : ''} alt="user" className='userImage' />
            </div>
            <div style={{ marginBottom: '2rem' }}>
                <div className="h4 bold d-flex-center">נסיעות הבאות לאריאל</div>
                {isHomeRidesLoaded ?
                <div>               
                 <Rides rides={communityRides} rideType={RideType.Offer} isAddRide={true} />
                {communityRides.length > 0 &&
                    <picture>
                        <source srcSet={CircleDotsImgWebp} type="image/webp" />
                        <img src={CircleDotsImgPng} alt="circle dots" loading="lazy" style={{ marginLeft: '0.5rem', marginTop: '0.5 rem' }}
                            onClick={() => openRidesPage(communityRides)} />
                    </picture>}
                           </div>
                : <Spinner />
}
               </div>
            <div>
                <div className="h4 bold d-flex-center">נסיעות הבאות ל{livesCity}</div>
                {isCommunityRidesLoaded ?
                <div> 
               <Rides rides={homeRides} rideType={RideType.Offer} isAddRide={true} />
                {homeRides.length > 0 &&
                    <picture>
                        <source srcSet={CircleDotsImgWebp} type="image/webp" />
                        <img src={CircleDotsImgPng} alt="circle dots" loading="lazy" style={{ marginLeft: '0.5rem', marginTop: '1 rem' }}
                            onClick={() => openRidesPage(homeRides)} />
                    </picture>}
                </div> :
                <Spinner />}         
   </div>
            <Button content="הוספת נסיעה" backgroundColor={Colors.buttons.dark} color='white' onClick={addRide} />
        </div>
    )

}

export default HomePage
