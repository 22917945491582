const Toggle = <T extends {}>({ parameterName, setParameter }:
    { parameterName: string, setParameter: React.Dispatch<React.SetStateAction<T>> }) => {
        
    const changeToggleState = (isChecked: boolean) => {
        setParameter((prevState: any) => ({
            ...prevState,
            [parameterName]: isChecked,
        }))
    }

    return (
        <label className="switch">
            <input type="checkbox" onChange={(e) => changeToggleState(e.target.checked)} />
            <span className="toggle round"></span>
        </label>
    )
}

export default Toggle;