import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import CarDrivingEntryPng from '../assets/img/png/CarDrivingEntry.png';
import LogoPng from '../assets/img/png/logo.png'
import CarDrivingEntryWebp from '../assets/img/webp/CarDrivingEntry.webp';
import LogoWebp from '../assets/img/webp/logo.webp'

export interface IWelocomePage { }

const WelocomePage: React.FunctionComponent<IWelocomePage> = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/login')
    }, 2000)
  }, [navigate])
  return (
    <div className='wrap-page'>
      <div className='h1 bold d-flex-center'>ברוך הבא</div>
      <picture>
        <source srcSet={LogoWebp} type="image/webp" />
        <img src={LogoPng} alt="logo" loading="lazy" style={{ width: '65%' }} />
      </picture>
      <picture>
        <source srcSet={CarDrivingEntryWebp} type="image/webp" />
        <img src={CarDrivingEntryPng} alt="CarDrivingEntry" loading="lazy" style={{ width: '85%' }} />
      </picture>
    </div>
  );
};

export default WelocomePage;