import { useState } from 'react';

import { useLocation } from 'react-router-dom';
import ContactUsIconPng from '../assets/img/png/contact-us-icon.png';
import ContactUsChosenIconPng from '../assets/img/png/contact-us-icon-chosen.png';
import ProfileIconPng from '../assets/img/png/profile-icon.png'
import ProfileIconChosenPng from '../assets/img/png/profile-icon-choosen.png'
import SearchIconPng from '../assets/img/png/search-icon.png'
import SearchIconChosenPng from '../assets/img/png/search-icon-chosen.png'
import AddDriveIconPng from '../assets/img/png/add-drive-icon.png'
import AddDriveIconChosenPng from '../assets/img/png/add-drive-icon-chosen.png'
import MyRidesPng from '../assets/img/png/my-rides-icon.png'
import MyRidesChsoenPng from '../assets/img/png/my-rides-icon-chosen.png'

import ContactUsIconWebp from '../assets/img/webp/contact-us-icon.webp';
import ContactUsChosenIconWebp from '../assets/img/webp/contact-us-icon-chosen.webp';
import ProfileIconWebp from '../assets/img/webp/profile-icon.webp'
import ProfileIconChosenWebp from '../assets/img/webp/profile-icon-choosen.webp'
import SearchIconWebp from '../assets/img/webp/search-icon.webp'
import SearchIconChosenWebp from '../assets/img/webp/search-icon-chosen.webp'
import AddDriveIconWebp from '../assets/img/webp/add-drive-icon.webp'
import AddDriveIconChosenWebp from '../assets/img/webp/add-drive-icon-chosen.webp'
import MyRidesWebp from '../assets/img/webp/my-rides-icon.webp'
import MyRidesChsoenWebp from '../assets/img/webp/my-rides-icon-chosen.webp'
import DecideShowRideTypePopup from './DecideShowRideTypePopup';

const BottomBar = () => {
    const location = useLocation();

    const [isOpenDecideShowRideTypePopup, setIsOpenDecideShowRideTypePopup] = useState<Boolean>(false);

    return (
        <div style={{ backgroundColor: '#2A3857', borderRadius: '27px', paddingTop: '1vh', paddingRight: '1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <picture>
                    <source srcSet={location.pathname.toLocaleLowerCase() === '/contactus' ? ContactUsChosenIconWebp : ContactUsIconWebp} type="image/webp" />
                    <img src={location.pathname.toLocaleLowerCase() === '/contactus' ? ContactUsChosenIconPng : ContactUsIconPng} alt="Start ride" loading="lazy" onClick={() => window.location.href = 'contactUs'} />
                </picture>
                <picture>
                    <source srcSet={location.pathname.toLocaleLowerCase() === ('/myrides') || location.pathname.toLocaleLowerCase() === ('/myjoiningrides') ? MyRidesChsoenWebp : MyRidesWebp} type="image/webp" />
                    <img src={location.pathname.toLocaleLowerCase() === ('/myrides') || location.pathname.toLocaleLowerCase() === ('/myjoiningrides') ? MyRidesChsoenPng : MyRidesPng} alt="Request join" loading="lazy" onClick={() => setIsOpenDecideShowRideTypePopup(true)} />
                </picture>
                <picture>
                    <source srcSet={location.pathname.toLocaleLowerCase() === '/adddrive' ? AddDriveIconChosenWebp : AddDriveIconWebp} type="image/webp" />
                    <img src={location.pathname.toLocaleLowerCase() === '/adddrive' ? AddDriveIconChosenPng : AddDriveIconPng} alt="Add drive" loading="lazy" onClick={() => window.location.href = 'addDrive'} />
                </picture>
                <picture>
                    <source srcSet={location.pathname.toLocaleLowerCase() === '/searchdrive' ? SearchIconChosenWebp : SearchIconWebp} type="image/webp" />
                    <img src={location.pathname.toLocaleLowerCase() === '/searchdrive' ? SearchIconChosenPng : SearchIconPng} alt="Search" loading="lazy" onClick={() => window.location.href = 'searchDrive'} />
                </picture>
                <picture>
                    <source srcSet={location.pathname.toLocaleLowerCase() === '/myprofile' ? ProfileIconChosenWebp : ProfileIconWebp} type="image/webp" />
                    <img src={location.pathname.toLocaleLowerCase() === '/myprofile' ? ProfileIconChosenPng : ProfileIconPng} alt="profile" loading="lazy" onClick={() => window.location.href = 'myProfile'} />
                </picture>
            </div>
            <div style={{ display: 'flex' }}>
                <div className="h8 center-text" style={{ color: 'white', width: '20%', paddingTop: '0.5rem' }}> צור קשר</div>
                <div className="h8 center-text" style={{ color: 'white', width: '20%' }}> הנסיעות שלי</div>
                <div className="h8 center-text" style={{ color: 'white', width: '20%' }}> הוספת נסיעה</div>
                <div className="h8 center-text" style={{ color: 'white', width: '20%', paddingTop: '0.5rem' }}> חיפוש</div>
                <div className="h8 center-text" style={{ color: 'white', width: '20%', paddingTop: '0.5rem' }}> פרופיל</div>
            </div>

            {isOpenDecideShowRideTypePopup && <DecideShowRideTypePopup handleClose={() => setIsOpenDecideShowRideTypePopup(!isOpenDecideShowRideTypePopup)} />}
        </div>
    )
}

export default BottomBar