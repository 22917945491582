import ShowRideProperties from "../models/rideProperties/ShowRideProperties";
import { CityOptions } from "../utils/options/CityOptions";
import EmptyRides from "./EmptyRides";
import RideBox from "./RideBox";
import { useState } from "react";
import RideProposition from "./RideProposition";
import RideType from "../enums/RideType";
import CancelRidePopup from "./CancelRidePopup";
import DeleteIdentityType from "../enums/DeleteIdentityType";

const Rides = ({ rides, rideType, isAddRide, deleteOption }: 
    { rides: Array<ShowRideProperties>, rideType: RideType, isAddRide?: boolean, deleteOption?: DeleteIdentityType }) => {

    const [isOpenRidePorpositionPopup, setIsOpenRidePorpositionPopup] = useState<boolean>(false);
    const [isOpenDeleteRidePopup, setIsOpenDeleteRidePopup] = useState<boolean>(false);
    const [rideInfoInsidePopup, setRidesInfoInsidePopup] = useState<ShowRideProperties>();

    const togglePopup = (popupRide: ShowRideProperties) => {
        isAddRide && setIsOpenRidePorpositionPopup(!isOpenRidePorpositionPopup);
        (deleteOption != undefined) && setIsOpenDeleteRidePopup(!isOpenDeleteRidePopup);
        setRidesInfoInsidePopup(popupRide);
    }
    const cancelPopupRideType = deleteOption === DeleteIdentityType.Driver ? RideType.CancelDriver : RideType.CancelJoiner;

    return (
        <div className="ridebox-wrapper d-flex-center-column" style={{ margin: '2rem 1rem 0 1rem', gap: '1rem' }}>
            {rides.length > 0 ?
                rides.map((ride: ShowRideProperties, index: number) => {
                    return (
                        <RideBox key={index}
                            currentRide={ride}
                            origin={CityOptions.find(option => option.value === ride.origin)!.text}
                            destination={CityOptions.find(option => option.value === ride.destination)!.text}
                            togglePopup={(popupRide: ShowRideProperties) => togglePopup(popupRide)} 
                            rideType={rideType}
                            buttonText={deleteOption == undefined ? 'להצטרף' : 'בטל'}/>
                    )
                })
                :
                <EmptyRides />

            }
            {isOpenRidePorpositionPopup && <RideProposition handleClose={() => setIsOpenRidePorpositionPopup(!isOpenRidePorpositionPopup)} rideInfoInsidePopup={rideInfoInsidePopup}/>}
            {isOpenDeleteRidePopup && <CancelRidePopup handleClose={() => setIsOpenDeleteRidePopup(!isOpenDeleteRidePopup)}
                                            rideInfoInsidePopup={rideInfoInsidePopup} rideType={cancelPopupRideType}/>}
        </div>
    )
}

export default Rides