
const Button = ({content, backgroundColor, color, small, medium, hide, onClick} :
     {content: string, backgroundColor: string, color: string, small?: boolean, medium?: boolean, hide?: boolean,  onClick: () => void }) => {

    return (
        <div className='d-flex-center'>
        <button style={{
            backgroundColor: backgroundColor,
            color: color,
            height: small ? '' : '55px',
            fontSize: medium ? '24px' : '28px',
            border: 'None',
            borderRadius: '10px',
            marginTop: small ? '' :'10px',
            marginBottom: small ? '' :'10px',
            width: small ? '125px' : medium ? '155px' : '180px',
            visibility: hide ? 'hidden' : 'visible',
	    cursor: 'pointer'
        }}
        onPointerEnter={onClick}>
            {content}
        </button>
    </div>
    )
}

export default Button
