import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import joiningToRideService from '../requests/joiningToRideServices';
import ShowRideProperties from '../models/rideProperties/ShowRideProperties';
import Rides from '../components/Rides';
import RideType from '../enums/RideType';
import JoinRidePng from '../assets/img/png/join-ride.png'
import JoinRideWebp from '../assets/img/webp/join-ride.webp'

export interface IJoinRequestsPage { }

const JoinRequestsPage: React.FunctionComponent<IJoinRequestsPage> = (props) => {

    const navigate = useNavigate();
    const { rideID } = useParams();
    const [requestsToJoin, setRequestsToJoin] = useState<Array<ShowRideProperties>>(new Array<ShowRideProperties>());
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        joiningToRideService.get(Number(rideID))
            .then((response: any) => {
                console.log("response.data", response.data)
                const newRequests = response.data.filter((ride: any) => {
                    return ride.isApproved == null && ride.isDeclined == null
                })
                console.log('newRequests', newRequests)
                setRequestsToJoin(newRequests);
		setIsReady(true);
            })
            .catch((e: AxiosError) => {
                const status = e.response?.status;
                if (status === 401 || status === 403) {
                    navigate(`/unauthorizeError/${rideID}`)
                } else {
                    navigate('/error')
                }
            });
    }, [rideID, navigate]);

    return (
        <div className='d-flex-center-column'>
            <div className='h3 bold' style={{ textAlign: 'center', width: '60%' }}>קיבלת בקשת הצטרפות</div>
            <picture>
                <source srcSet={JoinRideWebp} type="image/webp" />
                <img src={JoinRidePng} style={{ width: '60%' }} alt="join ride" loading="lazy" />
            </picture>
	    {isReady ?
                requestsToJoin.length > 0 ?
                    <Rides rides={requestsToJoin} rideType={RideType.Request} isAddRide={true} />
                     :
                    <div className='h5 bold center-text' style={{ marginTop: '3rem' }}>אין לך בקשות חדשות להצטרפות</div>
		: <div className='h3 bold' style={{marginTop: '3rem' }}>
			העמוד נטען
		</div>}            
        </div>
    )
}
export default JoinRequestsPage;
