import { Colors } from "../assets/colors/colors";
import ShieldIconPng from '../assets/img/png/shield-approve.png'
import ShieldIconWebp from '../assets/img/webp/shield-approve.webp'

const ApprovedCommunity = () => {

    return (
        <div className='d-flex-center' style={{ height: '80px', width: '100%', textAlign: 'center' }}>
            <div className='d-flex-center-column'
                style={{ backgroundColor: Colors.darkBlue, color: 'white', width: '70%', height: '100%', border: `1px solid ${Colors.darkBlue}`, borderRadius: '10px 0% 0% 10px' }}>
                <div className='h6 bold'>קהילת</div>
                <div className='h6 bold'>אוניברסיטת אריאל</div>
            </div>
            <div className='d-flex-center-column' style={{ width: '30%', height: '100%', border: `1px solid ${Colors.darkBlue}`, borderRadius: '0% 10% 10% 0%' }}>
                <picture>
                    <source srcSet={ShieldIconWebp} type="image/webp" />
                    <img src={ShieldIconPng} alt="shield" loading="lazy" width='40px'/>
                </picture>
                <div className='bold'>מאושר</div>
            </div>
        </div>
    )
}

export default ApprovedCommunity;