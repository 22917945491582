import ErrorImgPng from '../assets/img/png/error-img.png'
import ErrorImgWebp from '../assets/img/webp/error-img.webp'
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import { Colors } from '../assets/colors/colors';

export interface IErrorPage { }

const ErrorPage: React.FunctionComponent<IErrorPage> = (props) => {
    const navigate = useNavigate();

    return (
        <div className='d-flex-center-column' style={{ gap: '0.5rem', height: '78vh' }}>
            <div className='h3 bold'>אירעה שגיאה</div>
            <div className='h5'>נחזור בהקדם</div>
            <picture>
                <source srcSet={ErrorImgWebp} type="image/webp" />
                <img src={ErrorImgPng} alt="error" loading="lazy" />
            </picture>
            <Button content={"חזרה"} backgroundColor={Colors.buttons.dark} color={Colors.white}
                onClick={() => navigate(-1)} />
            <Button content={"דווח"} backgroundColor={Colors.buttons.dark} color={Colors.white}
                onClick={() => window.location.href = 'contactUs'} />
        </div>
    )
}

export default ErrorPage;