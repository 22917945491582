import axios from "axios";
const http =
  axios.create({
    baseURL: "https://graph.facebook.com/v17.0/",
    headers: {
      "Authorization": process.env.REACT_APP_WHATSUP_AUTH
    }
  });
const baseURL = `${process.env.REACT_APP_PHONE_ID}/messages/`

const rideStarted = async (phoneNumber: string, driver: string, from: string, to: string) => {
  return await http.post(`${baseURL}`, {
    "messaging_product": "whatsapp",
    "to": `972${phoneNumber}`,
    "type": "template",
    "template": {
      "name": "ride_has_started", "language": { "code": "he" }, "components": [{
        "type": "body",
        "parameters": [{
          "type": "text",
          "text": `${driver}`
        },
        {
          "type": "text",
          "text": `${from}`
        },
        {
          "type": "text",
          "text": `${to}`
        }]
      }]
    }
  });
};

const askToJoin = async (phoneNumber: string, joinRideRequester: string, rideID: number) => {
  return await http.post(`${baseURL}`, {
    "messaging_product": "whatsapp",
    "to": `972${phoneNumber}`,
    "type": "template",
    "template": {
      "name": "ask_to_join", "language": { "code": "he" }, "components": [{
        "type": "body",
        "parameters": [{
          "type": "text",
          "text": `${joinRideRequester}`
        }]
      },
      {
        "type": "button",
        "sub_type": "url",
        "index": 0,
        "parameters":
          [{
            "type": "text",
            "text": `joinRequestsPage/${rideID}`
          }]
      }]
    }
  });
}

const statusJoinToRideUpdated = async (phoneNumber: string, requestStatus: string, fromAndTo: string, date: string, driver: string) => {
  return await http.post(`${baseURL}`, {
    "messaging_product": "whatsapp",
    "to": `972${phoneNumber}`,
    "type": "template",
    "template": {
      "name": "status_joine_to_ride_update", "language": { "code": "he" }, "components": [{
        "type": "body",
        "parameters": [{
          "type": "text",
          "text": `${requestStatus}`
        },
        {
          "type": "text",
          "text": `${fromAndTo}`
        },
        {
          "type": "text",
          "text": `${date}`
        },
        {
          "type": "text",
          "text": `${driver}`
        }]
      }]
    }
  });
};

const contactUs = async (topic: string, content: string, email: string) => {
  return await http.post(`${baseURL}`, {
    "messaging_product": "whatsapp",
    "to": `972543352999`,
    "type": "template",
    "template": {
      "name": "contact_us", "language": { "code": "he" }, "components": [{
        "type": "body",
        "parameters": [{
          "type": "text",
          "text": `${topic}`
        },
        {
          "type": "text",
          "text": `${content}`
        },
	{
	  "type": "text",
	  "text": `${email}`
	}]
      }]
    }
  });
};

const cancelRideDriver = async (phoneNumber: string, driver: string, from: string, to: string, monthDate: string, datDate: string) => {
  return await http.post(`${baseURL}`, {
    "messaging_product": "whatsapp",
    "to": `972${phoneNumber}`,
    "type": "template",
    "template": {
      "name": "cancel_ride_driver", "language": { "code": "he" }, "components": [{
        "type": "body",
        "parameters": [{
          "type": "text",
          "text": `${driver}`
        },
        {
          "type": "text",
          "text": `${from}`
        },
        {
          "type": "text",
          "text": `${to}`
        },
        {
          "type": "text",
          "text": `${datDate}`
        },
        {
          "type": "text",
          "text": `${monthDate}`
        }]
      }]
    }
  });
};

const cancelRideJoiner = async (phoneNumber: string, joiner: string, from: string, to: string, monthDate: string, datDate: string) => {
  return await http.post(`${baseURL}`, {
    "messaging_product": "whatsapp",
    "to": `972${phoneNumber}`,
    "type": "template",
    "template": {
      "name": "cancel_ride_joiner", "language": { "code": "he" }, "components": [{
        "type": "body",
        "parameters": [{
          "type": "text",
          "text": `${joiner}`
        },
        {
          "type": "text",
          "text": `${from}`
        },
        {
          "type": "text",
          "text": `${to}`
        },
        {
          "type": "text",
          "text": `${datDate}`
        },
        {
          "type": "text",
          "text": `${monthDate}`
        }]
      }]
    }
  });
};

const WhatsupService = {
  rideStarted,
  askToJoin,
  statusJoinToRideUpdated,
  contactUs,
  cancelRideDriver,
  cancelRideJoiner
};

export default WhatsupService;
