import Stars from '../components/Stars';
import HomeIconPng from "../assets/img/png/home-icon.png"
import HomeIconWebp from "../assets/img/webp/home-icon.webp"
import { CityOptions } from '../utils/options/CityOptions';
import ApprovedCommunity from '../components/ApprovedCommunity';

export interface IMyProfilePage { }

const MyProfilePage: React.FunctionComponent<IMyProfilePage> = (props) => {

    const imgDataUrl = localStorage.getItem('imgDataUrl');
    const rate = localStorage.getItem('rate') || '0';
    const city = localStorage.getItem('city') || '';
    const translateCity = CityOptions.find(option => option.value === city);
    const street = localStorage.getItem('address');

    return (
        <div className="d-flex-center-column" style={{ gap: '1rem', width: '85vw', margin: 'auto' }}>
            <div className='h3 bold'>הפרופיל שלי</div>
            <img className='userImage' src={imgDataUrl ? imgDataUrl : ''} alt="user" />
            <div>
                <div className="d-flex-center h3" style={{ gap: '0.5rem' }}>
                    <div>{localStorage.getItem('age')}</div>
                    <div>{localStorage.getItem('name')}</div>
                </div>
                <Stars numberOfStars={parseInt(rate)} />
            </div>
            <ApprovedCommunity />
            <div className='regular-box d-flex-center full-width' style={{ boxSizing: 'border-box' }}>
                <div className='d-flex-center-column' style={{ alignItems: 'flex-end ' }}>
                    <div className='h6 bold'>בית</div>
                    <div className='h6'>{street}, {translateCity?.text}</div>
                </div>
                <picture>
                    <source srcSet={HomeIconWebp} type="image/webp" />
                    <img src={HomeIconPng} alt='house' style={{ height: '100%', paddingLeft: '1rem' }} loading="lazy" />
                </picture>
            </div>
        </div>
    )
}
export default MyProfilePage;
