export interface IGoodbyePage { }

const GoodbyePage: React.FunctionComponent<IGoodbyePage> = (props) => {

    return (
        <div className='d-flex-center-column' style={{ gap: '0.5rem', height: '78vh' }}>
            <h5>התשלום עבר בהצלחה, תודה ולהתראות</h5>
        </div>
    )
}

export default GoodbyePage;