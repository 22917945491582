import { Colors } from "../assets/colors/colors";
import { daysOfWeek } from "../utils/DaysWeek";
import Stars from "./Stars";


const CommentBox = ({ name, date, rate, comment }: { name: string, date: Date, rate: number, comment: string }) => {

    const dateTime = new Date(date);
    const dayWeek = daysOfWeek[dateTime.getDay()];
    const timezoneOffsetInMinutes = dateTime.getTimezoneOffset();
    const timezoneOffsetInMilliseconds = timezoneOffsetInMinutes * 60 * 1000;//Math.abs(timezoneOffsetInMinutes / 60);
    const dateWithGMTOffset = new Date(dateTime.getTime() - timezoneOffsetInMilliseconds);
    const day = dateWithGMTOffset.getDate();
    const month = dateWithGMTOffset.getMonth() + 1;
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`;

    return (
        <div className="flex-column full-width sky-background" style={{ borderRadius: '10px' }}>
            <div className="d-flex" style={{ justifyContent: 'space-between', height: '40px', paddingRight: '10px', paddingLeft: '10px' }}>
                <div className="d-flex-center h8">{dayWeek}, {formattedDate}</div>
                <div className="d-flex-center h5 bold">{name}</div>
            </div>
            <div className="d-flex" style={{ justifyContent: 'flex-end', height: '40px', paddingRight: '10px', paddingLeft: '10px' }}>
                <Stars numberOfStars={rate} isSmall={true} />
            </div>
            <div className="d-flex-center h8" style={{ backgroundColor: Colors.darkBlue, color: "white", height: '40px', borderRadius: '0px 0px 10px 10px' }}>
                {comment}
            </div>
        </div>
    )
}

export default CommentBox;