import IRatingParameters from "../models/IRatingParameters";
import getAxiosInstance from './httpCommon'; 

const baseURL = "/api/rate"
const axios = getAxiosInstance();

const get = async (email: string) => {
  const encodedEmail = encodeURIComponent(email);
  console.log('email', email)
  console.log('encodedEmail', encodedEmail)
    return await axios.get<Array<IRatingParameters>>(`${baseURL}`,
    { params: {
        email: email,
          }
      })
    };

  const create = async (rate: IRatingParameters) => {
    return await axios.post<IRatingParameters>(`${baseURL}`, rate);
};

const RateService = {
  get,
  create,
};

export default RateService;
