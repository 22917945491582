import FullStarPng from "../assets/img/png/full-star.png";
import EmptyStarPng from "../assets/img/png/empty-star.png";
import FullStarWebp from "../assets/img/webp/full-star.webp";
import EmptyStarWebp from "../assets/img/webp/empty-star.webp";

const Stars = ({ numberOfStars, isSmall }: { numberOfStars: number, isSmall?: boolean }) => {

  const size = isSmall ? '20px' : '30px'
console.log(numberOfStars)
  const renderImages = () => {
    const images = [];

    for (let i = 0; i < 5; i++) {
      if (i < numberOfStars) {
        images.push(
          <picture key={i}>
          <source srcSet={FullStarWebp} type="image/webp" />
          <img src={FullStarPng} alt="full star" loading="lazy" width={size} height={size}/>
      </picture>
        );
      } else {
        images.push(
          <picture key={i}>
          <source srcSet={EmptyStarWebp} type="image/webp" />
          <img src={EmptyStarPng} alt="empty star" loading="lazy" width={size} height={size}/>
      </picture>
        );
      }
    }
    return images;
  };

  return (
    <div className="d-flex-center" style={{ gap: '0.5rem' }}>
      {renderImages()}
    </div>
  )
}

export default Stars;